import React, { useState, useEffect } from "react";
import { Container, Card, CardBody, CardText, CardTitle } from "reactstrap";
import review from "../images/review.jpg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper/modules";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";
const testimonials = [
  {
    id: 1,
    image: review,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione quam vel consectetur omnis perferendis obcaecati iure dolores molestiae expedita officiis.",
    author: "John Doe",
    position: "CEO, Company A",
  },
  {
    id: 2,
    image: review,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione quam vel consectetur omnis perferendis obcaecati iure dolores molestiae expedita officiis.",
    author: "Jane Smith",
    position: "CTO, Company B",
  },
  {
    id: 3,
    image: review,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione quam vel consectetur omnis perferendis obcaecati iure dolores molestiae expedita officiis.",
    author: "Jane Smith",
    position: "CTO, Company C",
  },
  {
    id: 4,
    image: review,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione quam vel consectetur omnis perferendis obcaecati iure dolores molestiae expedita officiis.",
    author: "Jane Smith",
    position: "CTO, Company D",
  },
  {
    id: 5,
    image: review,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione quam vel consectetur omnis perferendis obcaecati iure dolores molestiae expedita officiis.",
    author: "Jane Smith",
    position: "CTO, Company E",
  },
  {
    id: 6,
    image: review,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione quam vel consectetur omnis perferendis obcaecati iure dolores molestiae expedita officiis.",
    author: "Jane Smith",
    position: "CTO, Company F",
  },
  // Add more testimonials as needed
];
const ReviewCarousel = () => {
  return (
    <Container className="my-5 ">
      <p className="text-center heading mb-3">Our Happy Clients Say About Us</p>
      <div className="w-50 mx-auto">
        <p className="text-center pt-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio aperiam
          corporis iste cum blanditiis tempore.
        </p>
      </div>

      <Swiper
        loop={true}
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper py-5 px-2"
      >
       {testimonials.map((testimonial, index) => (
  <SwiperSlide key={index}>
  <div className="d-flex flex-column h-100">
    <Card className="py-5 rounded-5 border-0 d-flex flex-column bg-body-tertiary testimonial-shadow" style={{ height: "400px" }}>
      <CardBody className="px-md-4 px-2 pb-0">
        <CardText>
          <FaQuoteLeft className="mx-2 fs-3" /> {testimonial.text} <FaQuoteRight className="mx-2 fs-3" />
        </CardText>
      </CardBody>
      <div className="d-md-flex justify-content-evenly pt-5">
      <div>
  <img
    src={testimonial.image}
    alt=""
    className="rounded-circle border border-dark"
    style={{
      width: "80px",
      height: "80px",
      objectFit: "cover",
    }}
  />
</div>

        <div className="fs-6 my-auto">
          <CardTitle className="text-center fw-bolder text-capitalize">
            {testimonial.author}
          </CardTitle>
          <p className="text-center mb-0 fst-italic">
            {testimonial.position}
          </p>
        </div>
      </div>
    </Card>
  </div>
</SwiperSlide>

))}

      </Swiper>
    </Container>
  );
};
export default ReviewCarousel;
