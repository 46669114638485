import {
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import { useRef } from "react";
import Lottie from "lottie-react";
import contactanimation from "../images/contactanimation.json";
import { useUserAuth } from "../Context/Context";
import withScrollToTop from "./scrollToTop";
const Contact = () => {
  const {contactUs} = useUserAuth();
  const FirstnameRef = useRef();
  const LastnameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const messageRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    contactUs(FirstnameRef.current.value, LastnameRef.current.value, emailRef.current.value, mobileRef.current.value, messageRef.current.value)
  }

  return (
    <>
    <div className="d-flex justify-content-center min-vh-100">

    <Container fluid className="py-5  text-dark">
        <p className="heading text-capitalize text-center ">
          Get in touch with us
        </p>
        <Container className="p-md-5 p-4 bg-white rounded-5 shadow-lg my-5">
          <Row>
            <Col className="d-flex justify-content-center" md={8}>
              <Form className=" my-auto w-100 mx-md-5" onSubmit={handleSubmit}>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Enter your first name"
                    className="fs-6 my-3  rounded-4 shadow-sm px-4 py-2 border-dark-subtle border-1"
                    style={{ boxShadow: "none" }}
                    innerRef={FirstnameRef}
                    required
                  />
                  <Input
                    type="text"
                    placeholder="Enter your last name"
                    className="fs-6 my-3  rounded-4 shadow-sm px-4 py-2 border-dark-subtle border-1"
                    style={{ boxShadow: "none" }}
                    innerRef={LastnameRef}
                    required
                  />
                  <Input
                    required
                    type="email"
                    placeholder="Enter your email"
                    className="fs-6 my-3  rounded-4 shadow-sm px-4 py-2 border-dark-subtle border-1"
                    style={{ boxShadow: "none" }}
                    innerRef={emailRef}
                  />
                  <Input
                    type="number"
                    placeholder="Enter your phone number"
                    className="fs-6 my-3  rounded-4 shadow-sm px-3 py-2 border-dark-subtle border-1"
                    style={{ boxShadow: "none" }}
                    innerRef={mobileRef}
                  />
                  <Input
                    required
                    type="textarea"
                    placeholder="Enter your message"
                    className="fs-6 my-3  rounded-4 shadow-sm px-4 py-2 border-dark-subtle border-1"
                    style={{ boxShadow: "none" }}
                    innerRef={messageRef}
                  />
                </FormGroup>
                <Button
                type="submit"
                  color="primary"
                  className="px-5 py-2 fs-4  mt-3"
                  style={{
                    fontFamily: "sans-serif",
                    transition: "background-color 0.7s, color 0.2s, border 0s", // Add transition properties for border
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "transparent";
                    e.target.style.color = "#0d6efd"; // Change text color to white
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "";
                    e.target.style.color = ""; // Restore the original text color
                  }}
                >
                  Submit
                </Button>
              </Form>
            </Col>
            <Col className="d-flex justify-content-center align-items-center" md={4}>
              <Container>
                <Lottie
                  animationData={contactanimation}
                  className="d-none d-md-block mx-auto bg-transparent"
                 
                />
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
      
    </>
  );
};
export default withScrollToTop(Contact);
