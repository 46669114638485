import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import withScrollToTop from "../components/scrollToTop";
const Conditions = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <>
      <Container fluid className="min-vh-100 pt-5">
        <p className="text-center heading my-5 ">Terms & Conditions</p>
        <div>
          <Row>
            <Col md={3} className="mb-5 mb-md-0">
              <Nav vertical tabs>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "1" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("1")}
                  >
                    Introduction
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "2" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("2")}
                  >
                    Access to the Site
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "3" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("3")}
                  >
                    User Content
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "4" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("4")}
                  >
                    Third Party Links and Ads
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "5" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("5")}
                  >
                    Disclaimers
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "6" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("6")}
                  >
                    Limitations on Liability
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "7" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("7")}
                  >
                    Copyright Policy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "8" ? "bg-primary text-white" : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("8")}
                  >
                    General
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col
              md={8}
              className="h-100 mx-auto"
              style={{ minHeight: "500px" }}
            >
              <TabContent activeTab={activeTab} className="mb-5 pb-5">
                <TabPane tabId="1">
                  <h3>Introduction</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    The Grab.io website is a copyrighted work belonging to
                    Codeulas. Certain features of the Site may be subject to
                    additional guidelines, terms, or rules, which will be posted
                    on the site in connection with such features. All such
                    additional terms, guidelines, and rules are incorporated by
                    reference into these Terms. These terms of use described the
                    legally binding terms and conditions that oversee your use
                    of the site. BY LOGGING INTO THE SITE, YOU ARE BEING
                    COMPLIANT THAT THESE TERMS and you represent that you have
                    the authority and capacity to enter into these terms. YOU
                    SHOULD BE AT LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF
                    YOU DISAGREE WITH ALL OF THE PROVISION OF THESE TERMS, DO
                    NOT LOG INTO AND/OR USE THE SITE.
                  </p>
                </TabPane>
                <TabPane tabId="2">
                  <h3>Access to the Site</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    <span className="fw-bolder text-secondary">
                      Subject to these Terms.
                    </span>{" "}
                    Codeulas on behalf of Grab.io grants you a
                    non-transferable, non-exclusive, revocable, limited license
                    to access the Site solely for your own personal,
                    noncommercial use. <br /> <br />{" "}
                    <span className="fw-bolder text-secondary">
                      {" "}
                      Certain Restrictions.
                    </span>{" "}
                    The rights approved to you in these Terms are subject to the
                    following restrictions: (a) you shall not sell, rent, lease,
                    transfer, assign, distribute, host, or otherwise
                    commercially exploit the Site; (b) you shall not change,
                    make derivative works of, disassemble, reverse compile or
                    reverse engineer any part of the Site; (c) you shall not
                    access the Site in order to build a similar or competitive
                    website; and (d) except as expressly stated herein, no part
                    of the Site may be copied, reproduced, distributed,
                    republished, downloaded, displayed, posted or transmitted in
                    any form or by any means unless otherwise indicated, any
                    future release, update, or other addition to functionality
                    of the Site shall be subject to these Terms. All copyright
                    and other proprietary notices on the Site must be retained
                    on all copies thereof. <br /> <br /> Codeulas reserves the
                    right to change, suspend, or cease the Site with or without
                    notice to you. You approved that the Codeulas will not be
                    held liable to you or any third-party for any change,
                    interruption, or termination of the Site or any part. <br />{" "}
                    <br />{" "}
                    <span className="fw-bolder text-secondary">
                      No Support or Maintenance.
                    </span>{" "}
                    You agree that Codeulas will have no obligation to provide
                    you with any support in connection with the Site. Excluding
                    any User Content that you may provide, you are aware that
                    all the intellectual property rights, including copyrights,
                    patents, trademarks, and trade secrets, in the Site and its
                    content are owned by Codeulas or Codeulas’s suppliers. Note
                    that these Terms and access to the Site do not give you any
                    rights, title or interest in or to any intellectual property
                    rights, except for the limited access rights. Codeulas and
                    its suppliers reserve all rights not granted in these Terms.
                  </p>
                </TabPane>
                <TabPane tabId="3">
                  <h3>User Content</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    <span className="fw-bolder text-secondary">
                      User Content.
                    </span>
                    "User Content" means any and all information and content
                    that a user submits to the Site. You are exclusively
                    responsible for your User Content. You bear all risks
                    associated with use of your User Content. You hereby certify
                    that your User Content does not violate our Acceptable Use
                    Policy. You may not represent or imply to others that your
                    User Content is in any way provided, sponsored or endorsed
                    by the Codeulas. Because you alone are responsible for your
                    User Content, you may expose yourself to liability. Codeulas
                    is not obliged to backup any User Content that you post;
                    also, your User Content may be deleted at any time without
                    prior notice to you. You are solely responsible for making
                    your own backup copies of your User Content if you desire.{" "}
                    <br /> <br />
                    You hereby grant to Codeulas an irreversible, nonexclusive,
                    royalty-free and fully paid, worldwide license to reproduce,
                    distribute, publicly display and perform, prepare derivative
                    works of, incorporate into other works, and otherwise use
                    and exploit your User Content, and to grant sublicenses of
                    the foregoing rights, solely for the purposes of including
                    your User Content in the Site. You hereby irreversibly waive
                    any claims and assertions of moral rights or attribution
                    with respect to your User Content. <br /> <br />
                    <span className="fw-bolder text-secondary">
                      Acceptable Use Policy.
                    </span>
                    The following terms constitute our "Acceptable Use Policy":
                    You agree not to use the Site to collect, upload, transmit,
                    display, or distribute any User Content (i) that violates
                    any third-party right or any intellectual property or
                    proprietary right; (ii) that is unlawful, harassing,
                    abusive, tortious, threatening, harmful, invasive of
                    another’s privacy, vulgar, defamatory, false, intentionally
                    misleading, trade libelous, pornographic, obscene, patently
                    offensive, promotes racism, bigotry, hatred, or physical
                    harm of any kind against any group or individual; (iii) that
                    is harmful to minors in any way; or (iv) that is in
                    violation of any law, regulation, or obligations or
                    restrictions imposed by any third party. <br /> <br />
                    In addition, you agree not to: (i) upload, transmit, or
                    distribute to or through the Site any software intended to
                    damage or alter a computer system or data; (ii) send through
                    the Site unsolicited or unauthorized advertising,
                    promotional materials, junk mail, spam, chain letters,
                    pyramid schemes, or any other form of duplicative or
                    unsolicited messages; (iii) use the Site to harvest,
                    collect, gather or assemble information or data regarding
                    other users without their consent; (iv) interfere with,
                    disrupt, or create an undue burden on servers or networks
                    connected to the Site, or violate the regulations, policies
                    or procedures of such networks; (v) attempt to gain
                    unauthorized access to the Site, whether through password
                    mining or any other means; (vi) harass or interfere with any
                    other user’s use and enjoyment of the Site; or (vi) use
                    software or automated agents or scripts to produce multiple
                    accounts on the Site, or to generate automated searches,
                    requests, or queries to the Site. <br /> <br />
                    We reserve the right to review any User Content, and to
                    investigate and/or take appropriate action against you in
                    our sole discretion if you violate the Acceptable Use Policy
                    or any other provision of these Terms or otherwise create
                    liability for us or any other person. Such action may
                    include removing or modifying your User Content, terminating
                    your Account. <br /> <br />
                    If you provide Codeulas with any feedback or suggestions
                    regarding the Site, you hereby assign to Codeulas all rights
                    in such Feedback and agree that Codeulas shall have the
                    right to use and fully exploit such Feedback and related
                    information in any manner it believes appropriate. Codeulas
                    will treat any Feedback you provide to Codeulas as
                    non-confidential and non-proprietary. <br /> <br />
                    You agree to indemnify and hold Codeulas and its officers,
                    employees, and agents harmless, including costs and
                    attorneys’ fees, from any claim or demand made by any
                    third-party due to or arising out of (a) your use of the
                    Site, (b) your violation of these Terms, (c) your violation
                    of applicable laws or regulations or (d) your User Content.
                    Codeulas reserves the right to assume the exclusive defense
                    and control of any matter for which you are required to
                    indemnify us, and you agree to cooperate with our defense of
                    these claims. You agree not to settle any matter without the
                    prior written consent of the Codeulas. Codeulas will use
                    reasonable efforts to notify you of any such claim, action
                    or proceeding upon becoming aware of it.
                  </p>
                </TabPane>
                <TabPane tabId="4">
                  <h3>Third Party Links & Ads</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    <span className="fw-bolder text-secondary">
                      Third-Party Links & Ads.
                    </span>
                    The Site may contain links to third-party websites and
                    services, and/or display advertisements for third-parties.
                    Such Third-Party Links & Ads are not under the control of
                    Codeulas, and Codeulas is not responsible for any
                    Third-Party Links & Ads. Codeulas provides access to these
                    Third-Party Links & Ads only as a convenience to you, and
                    does not review, approve, monitor, endorse, warrant, or make
                    any representations with respect to Third-Party Links & Ads.
                    You use all Third-Party Links & Ads at your own risk, and
                    should apply a suitable level of caution and discretion in
                    doing so. When you click on any of the Third-Party Links &
                    Ads, the applicable third party’s terms and policies apply,
                    including the third party’s privacy and data gathering
                    practices. <br /> <br />
                    <span className="fw-bolder text-secondary">
                      Other Users.
                    </span>
                    Each Site user is solely responsible for any and all of its
                    own User Content. Because we do not control User Content,
                    you acknowledge and agree that we are not responsible for
                    any User Content, whether provided by you or by others. You
                    agree that the Codeulas will not be responsible for any loss
                    or damage incurred as the result of any such interactions.
                    If there is a dispute between you and any Site user, we are
                    under no obligation to become involved. <br /> <br />
                    <span className="fw-bolder text-secondary">
                      Cookies and Web Beacons.
                    </span>
                    Like any other website, Codeulas uses ‘cookies’. These
                    cookies are used to store information including visitors’
                    preferences, and the pages on the website that the visitor
                    accessed or visited. The information is used to optimize the
                    users’ experience by customizing our web page content based
                    on visitors’ browser type and/or other information.
                  </p>
                </TabPane>
                <TabPane tabId="5">
                  <h3>Disclaimers</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    The site is provided on an "as-is" and "as available" basis,
                    and Codeulas and our suppliers expressly disclaim any and
                    all warranties and conditions of any kind, whether express,
                    implied, or statutory, including all warranties or
                    conditions of merchantability, fitness for a particular
                    purpose, title, quiet enjoyment, accuracy, or
                    non-infringement. We and our suppliers make no guarantee
                    that the site will meet your requirements, will be available
                    on an uninterrupted, timely, secure, or error-free basis, or
                    will be accurate, reliable, free of viruses or other harmful
                    code, complete, legal, or safe. If applicable law requires
                    any warranties with respect to the site, all such warranties
                    are limited in duration to ninety (90) days from the date of
                    first use. <br /> <br />
                    Some jurisdictions do not allow the exclusion of implied
                    warranties, so the above exclusion may not apply to you.
                    Some jurisdictions do not allow limitations on how long an
                    implied warranty lasts, so the above limitation may not
                    apply to you.
                  </p>
                </TabPane>
                <TabPane tabId="6">
                  <h3>Limitations on Liability</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    To the maximum extent permitted by law, in no event shall
                    Codeulas or our suppliers be liable to you or any
                    third-party for any lost profits, lost data, costs of
                    procurement of substitute products, or any indirect,
                    consequential, exemplary, incidental, special or punitive
                    damages arising from or relating to these terms or your use
                    of, or incapability to use the site even if Codeulas has
                    been advised of the possibility of such damages. Access to
                    and use of the site is at your own discretion and risk, and
                    you will be solely responsible for any damage to your device
                    or computer system, or loss of data resulting therefrom.{" "}
                    <br /> <br />
                    To the maximum extent permitted by law, notwithstanding
                    anything to the contrary contained herein, our liability to
                    you for any damages arising from or related to this
                    agreement, will at all times be limited to a maximum of
                    fifty U.S. dollars (u.s. $50). The existence of more than
                    one claim will not enlarge this limit. You agree that our
                    suppliers will have no liability of any kind arising from or
                    relating to this agreement. <br /> <br />
                    Some jurisdictions do not allow the limitation or exclusion
                    of liability for incidental or consequential damages, so the
                    above limitation or exclusion may not apply to you. <br />{" "}
                    <br />
                    <span className="fw-bolder text-secondary">
                      Term and Termination.
                    </span>
                    Subject to this Section, these Terms will remain in full
                    force and effect while you use the Site. We may suspend or
                    terminate your rights to use the Site at any time for any
                    reason at our sole discretion, including for any use of the
                    Site in violation of these Terms. Upon termination of your
                    rights under these Terms, your Account and right to access
                    and use the Site will terminate immediately. You understand
                    that any termination of your Account may involve deletion of
                    your User Content associated with your Account from our live
                    databases. Codeulas will not have any liability whatsoever
                    to you for any termination of your rights under these Terms.
                  </p>
                </TabPane>
                <TabPane tabId="7">
                  <h3>Copyright Policy</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    Codeulas respects the intellectual property of others and
                    asks that users of our Site do the same. In connection with
                    our Site, we have adopted and implemented a policy
                    respecting copyright law that provides for the removal of
                    any infringing materials and for the termination of users of
                    our online Site who are repeat infringers of intellectual
                    property rights, including copyrights. If you believe that
                    one of our users is, through the use of our Site, unlawfully
                    infringing the copyright(s) in a work, and wish to have the
                    allegedly infringing material removed, the following
                    information in the form of a written notification must be
                    provided to our designated Copyright Agent: <br /> <br />
                    <ul>
                      <li className="my-3">
                        Your physical or electronic signature;
                      </li>
                      <li className="my-3">
                        Identification of the copyrighted work(s) that you claim
                        to have been infringed;
                      </li>
                      <li className="my-3">
                        Identification of the material on our services that you
                        claim is infringing and that you request us to remove;
                      </li>
                      <li className="my-3">
                        Sufficient information to permit us to locate such
                        material;
                      </li>
                      <li className="my-3">
                        Your address, telephone number, and e-mail address;
                      </li>
                      <li className="my-3">
                        A statement that you have a good faith belief that use
                        of the objectionable material is not authorized by the
                        copyright owner, its agent, or under the law;
                      </li>
                      <li className="my-3">
                        A statement that the information in the notification is
                        accurate, and under penalty of perjury, that you are
                        either the owner of the copyright that has allegedly
                        been infringed or that you are authorized to act on
                        behalf of the copyright owner.
                      </li>
                    </ul>
                  </p>
                </TabPane>
                <TabPane tabId="8">
                  <h3>General</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    These Terms are subject to occasional revision, and if we
                    make any substantial changes, we may notify you by sending
                    you an e-mail to the last e-mail address you provided to us
                    and/or by prominently posting notice of the changes on our
                    Site. You are responsible for providing us with your most
                    current email address. In the event that the last e-mail
                    address that you have provided us is not valid, our dispatch
                    of the e-mail containing such notice will nonetheless
                    constitute effective notice of the changes described in the
                    notice. Any changes to these Terms will be effective upon
                    the earliest of thirty (30) calendar days following our
                    dispatch of an email notice to you or thirty (30) calendar
                    days following our posting of notice of the changes on our
                    Site. These changes will be effective immediately for new
                    users of our Site. Continued use of our Site following
                    notice of such changes shall indicate your acknowledgement
                    of such changes and agreement to be bound by the terms and
                    conditions of such changes. Dispute Resolution. Please read
                    this Arbitration Agreement carefully. It is part of your
                    contract with the Codeulas and affects your rights.
                  </p>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default withScrollToTop(Conditions);
