import React, { useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import withScrollToTop from "../components/scrollToTop";
const Privacy = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <>
      <Container fluid className="min-vh-100 pt-5">
        <p className="text-center heading my-5">Privacy Policy</p>
        <div>
          <Row>
            <Col md={3} sm={10}  className="mb-5 mb-md-0">
              <Nav vertical tabs>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "1"
                        ? "bg-primary text-white"
                        : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("1")}
                  >
                    Introduction
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "2"
                        ? "bg-primary text-white"
                        : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("2")}
                  >
                    Information we collect
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "3"
                        ? "bg-primary text-white"
                        : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("3")}
                  >
                    How we use your information
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "4"
                        ? "bg-primary text-white"
                        : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("4")}
                  >
                    Log Files
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "5"
                        ? "bg-primary text-white"
                        : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("5")}
                  >
                    Cookies and Web Beacons
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={` fw-bolder fs-5 ${
                      activeTab === "6"
                        ? "bg-primary text-white"
                        : "text-dark-emphasis"
                    }`}
                    onClick={() => toggleTab("6")}
                  >
                    Children's Information
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col
              md={8} sm={10}
              className="h-100 mx-auto"
              style={{ minHeight: "400px" }}
            >
              <TabContent activeTab={activeTab} className="mb-5 pb-5">
                <TabPane tabId="1">
                  <h3>Introduction</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    At Grab.io, one of our main priorities is the privacy of
                    our visitors. This Privacy Policy document contains types of
                    information that is collected and recorded by Grab.io and
                    how we use it. <br /> <br /> If you have additional
                    questions or require more information about our Privacy
                    Policy, do not hesitate to contact us. <br /> <br />
                    This Privacy Policy applies only to our online activities
                    and is valid for visitors to our website with regards to the
                    information that they shared and/or collect in Grab.io.
                    This policy is not applicable to any information collected
                    offline or via channels other than this website. Grab.io
                    maintains all the consumer protection act and all data
                    protection rights for users. <br /> <br /> By using our
                    website, you hereby consent to our Privacy Policy and agree
                    to its terms.
                  </p>
                </TabPane>
                <TabPane tabId="2">
                  <h3 className="text-capitalize">Information we collect</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    The personal information that you are asked to provide, and
                    the reasons why you are asked to provide it, will be made
                    clear to you at the point we ask you to provide your
                    personal information. <br /> <br /> If you contact us
                    directly, we may receive additional information about you
                    such as your name, email address, phone number, the contents
                    of the message and/or attachments you may send us, and any
                    other information you may choose to provide. <br /> <br />{" "}
                    When you register for an Account, we may ask for your
                    contact information, including items such as name, company
                    name, address, email address, and telephone number.
                  </p>
                </TabPane>
                <TabPane tabId="3">
                  <h3 className="text-capitalize">How we use your Information</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    We use the information we collect in various ways, including
                    to: <br /> <br />
                    <ul>
                      <li className="mb-3">
                        Provide, operate, and maintain our website
                      </li>
                      <li className="my-3">
                        Improve, personalize, and expand our website
                      </li>
                      <li className="my-3">
                        Understand and analyze how you use our website
                      </li>
                      <li className="my-3">
                        Develop new products, services, features, and
                        functionality
                      </li>
                      <li className="my-3">
                        Communicate with you, either directly or through one of
                        our partners, including for customer service, to provide
                        you with updates and other information relating to the
                        website, and for marketing and promotional purposes
                      </li>
                      <li className="my-3">Send you emails</li>
                      <li className="my-3">Find and prevent fraud</li>
                    </ul>
                  </p>
                </TabPane>
                <TabPane tabId="4">
                  <h3>Log Files</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    Grab.io follows a standard procedure of using log files.
                    These files log visitors when they visit websites. All
                    hosting companies do this as part of hosting services'
                    analytics. The information collected by log files include
                    internet protocol (IP) addresses, browser type, Internet
                    Service Provider (ISP), date and time stamp, referring/exit
                    pages, and possibly the number of clicks. These are not
                    linked to any information that is personally identifiable.
                    The purpose of the information is for analyzing trends,
                    administering the site, tracking users' movement on the
                    website, and gathering demographic information.
                  </p>
                </TabPane>
                <TabPane tabId="5">
                  <h3>Cookies and Web Beacons</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                    Like any other website, Grab.io uses 'cookies'. These
                    cookies are used to store information including visitors'
                    preferences, and the pages on the website that the visitor
                    accessed or visited. The information is used to optimize the
                    users' experience by customizing our web page content based
                    on visitors' browser type and/or other information.
                  </p>
                </TabPane>
                <TabPane tabId="6">
                  <h3>Children's Information</h3>
                  <p className="fs-5 text-dark-emphasis my-3">
                  Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                  </p>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default withScrollToTop(Privacy);
