import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useNavigate, useLocation } from "react-router-dom";
import { Progress } from "reactstrap";
import { country } from "../country";
const UserContext = createContext();
export function useUserAuth() {
  return useContext(UserContext);
}

export function UserContextProvider({ children }) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [userMail, setUserMail] = useState();
  const [scrdata, setScrdata] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (user && (path.includes("signin") || path.includes("signup"))) {
      navigate("/");
    }
  }, [user, location.pathname]);


  useEffect(() => {
    const auth = localStorage.getItem("userlogin");
    if (auth) {
      setUser(JSON.parse(auth));
    }
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("userlogin", JSON.stringify(user));
    }
  }, [user]);


  async function register(
    firstname,
    lastname,
    Email,
    phone,
    country,
    password
  ) {
    const loadingToastId = toast.info("Logging in...", { autoClose: false });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/account/login/create_account`,
        {
          first_name: firstname,
          last_name: lastname,
          email: Email,
          mobile: phone,
          country: country,
          password: password,
        }
      );

      // Hide loading toast
      toast.dismiss(loadingToastId);
      console.log(res);
      const { data } = res;
      console.log(data);
      const { status, activation_key, email } = data;

      if (status === "success") {
        setUserMail(email);
        // Show success toast
        toast.success(data.message || "You are registered successfully");

        // Navigate to the desired location
        navigate(`/signin/verification/${activation_key}`);
      } else {
        // Throw an error to stop execution
        throw new Error(data.message);
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      // Handle errors here, if necessary
      console.error("An error occurred during registration:", error);

      // Show error toast
      toast.error(error.message || "An error occurred");
    }
  }

  function login(email, password) {
    // Show loading toast
    const loadingToastId = toast.info("Logging in...", { autoClose: false });

    return axios
      .post(`${process.env.REACT_APP_BASE_URI}/account/login/log_in`, {
        email: email,
        password: password,
      })
      .then((res) => {
        // Hide loading toast
        toast.dismiss(loadingToastId);
        console.log(res);
        const { data } = res;
        console.log(data);

        // Check status and take appropriate action
        const { status, status_code, activation_key } = data;
        if (status === "success") {
          setUser(data);
          // Show success toast
          toast.success(data.message || "Logged In successfully");

          // Navigate to the desired location
          navigate("/");
        } else if (status_code === 401) {
          navigate(`/signin/verification/${activation_key}`);
        } else {
          // Throw an error to stop execution
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        toast.dismiss(loadingToastId);
        // Handle errors here, if necessary
        console.error("An error occurred during login:", error);

        // Show error toast
        toast.error(error.message || "An error occurred");
        return;
      });
  }

  function logout() {
    setUser(null);
    localStorage.removeItem("userlogin");
    navigate("/");
    toast("You have been logged out!", { type: "success" });
  }

  function forgetpassword(email) {
    const loadingToastId = toast.info("Sending email...", { autoClose: false });

    axios
      .post(`${process.env.REACT_APP_BASE_URI}/account/login/forgotPassword`, {
        email: email,
      })
      .then((res) => {
        toast.dismiss(loadingToastId);
        const { data } = res;
        const { status } = data;
        if (status === "success") {
          // Show success toast
          toast.success(data.message || "Recovery email sent successfully");
        } else {
          // Throw an error to stop execution
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        toast.dismiss(loadingToastId);
        console.log(error);
        toast.error(error.message || "An error occurred");
        return;
      });
  }

  function resetpassword(newpass, confirmpass, key) {
    const loadingToastId = toast.info("Changing password...", {
      autoClose: false,
    });

    axios
      .post(
        `${process.env.REACT_APP_BASE_URI}/account/login/updatePassword/${key}`,
        {
          pass1: newpass,
          pass2: confirmpass,
        }
      )
      .then((res) => {
        toast.dismiss(loadingToastId);
        const { data } = res;
        const { status } = data;
        if (status === "success") {
          // Show success toast
          toast.success(data.message || "Password changed successfully");
          navigate("/signin");
        } else {
          // Throw an error to stop execution
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        toast.dismiss(loadingToastId);
        toast.error(error.message || "An error occurred");
        return;
      });
  }

  async function updateProfile(firstname, lastname, email, phone, country) {
    const loadingToastId = toast.info(" Updating Profile...", {
      autoClose: false,
    });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/account/profile/updateProfile`,
        {
          first_name: firstname,
          last_name: lastname,
          email: email,
          mobile: phone,
          country: country,
        }
      );

      // Hide loading toast
      toast.dismiss(loadingToastId);

      console.log(res);
      const { data } = res;
      console.log(data);
      const { status } = data;

      if (status === "success") {
        setUser(data);

        // Show success toast
        toast.success(data.message || "Profile updated successfully");
      } else {
        // Throw an error to stop execution
        throw new Error(data.message);
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error("An error occurred during profile update:", error);
      toast.error(error.message || "An error occurred");
    }
  }

  async function getProfileDetails(accountId)
  {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/account/profile/userProfileDetails`,
        {
          accountid:accountId
        }
      );
      const { data } = res;
      const { status } = data;
      if (status === "success") {
        setUser(data);
        return true;
      } else {
        throw new Error("No data found");
      }
    } catch (error) {
      throw error;
    }
  }  

  async function changePassword(oldpass, newpass, token) {
    const loadingToastId = toast.info(" Changing Password...", {
      autoClose: false,
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/account/profile/changePassword`,
        {
          old_pass: oldpass,
          new_pass: newpass,
          token: token,
        }
      );

      toast.dismiss(loadingToastId);

      console.log(res);
      const { data } = res;
      console.log(data);
      const { status } = data;

      if (status === "success") {
        // Show success toast
        toast.success(data.message || "Password changed successfully");
        logout();
      } else {
        // Throw an error to stop execution
        throw new Error(data.message);
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error("An error occurred during profile update:", error);
      toast.error(error.message || "An error occurred");
    }
  }

  async function contactUs(firstname, lastname, email, mobile, message) {
    const loadingToastId = toast.info(" Sending Message...", {
      autoClose: false,
    });
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/common/contact/contactUs`,
        {
          first_name: firstname,
          last_name: lastname,
          email: email,
          phone: mobile,
          message: message,
        }
      );

      toast.dismiss(loadingToastId);

      console.log(res);
      const { data } = res;
      console.log(data);
      const { status } = data;

      if (status === "success") {
        // Show success toast
        toast.success(data.message || "Message sent successfully");
      } else {
        // Throw an error to stop execution
        throw new Error(data.message);
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error("An error occurred during profile update:", error);
      toast.error(error.message || "An error occurred");
    }
  }

  async function emailValidate(key) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/account/login/validate/${key}`
      );
      const { data } = res;
      const { status } = data;
      if (status === "success") {
        navigate("/signin");
        toast.success("Email verified");
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      
      console.error("An error occurred during profile update:", error);
      toast.error(error.message || "An error occurred");
    }
  }

  async function emailResend(key) {
    const loadingToastId = toast.info(" Sending Message...", {
      autoClose: false,
    });
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/account/login/resend_code/${key}`
      );
      toast.dismiss(loadingToastId);
      const { data } = res;
      const { status } = data;
      if (status === "success") {
        toast.success("Email re-sent successfully");
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error("An error occurred during profile update:", error);
      toast.error(error.message || "An error occurred");
    }
  }

  async function getCountryCode() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/common/getcountry/getCountry`
      );
      const {
        data: { countries },
      } = res;
      // const searchCode = countries.filter((item) =>
      //   item.name.includes(country)
      // );

      if (countries) {
        return countries;
      } else {
        throw new Error("Country not found");
      }
    } catch (error) {
      console.error("An error occurred during country code retrieval:", error);
      throw error;
    }
  }

  async function getPricing() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/pages/pricing/allpackages`
      );
      const { data } = res;
      const { status } = data;
      if (status === "success") {
        return data;
      } else {
        throw new Error("Pricing not available");
      }
    } catch (error) {
      console.error("Error: ", error);
      throw error;
    }
  }

  async function getSubscriptionDetails(accountId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URI}/pages/pricing/user_subscription`,
        {
          method: "POST",
          body: JSON.stringify({
            accountid: accountId,
          }),
        }
      );

      const data = await response.json();

      if (data.status === "success") {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  async function searchBalance(accountId, type) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/account/balance`,
        {
          accountid: accountId,
          status: type,
        }
      );
      const { data } = res;
      const { status, limit } = data;
      if (status === "success") {
        return limit;
      } else {
        throw new Error("Data not available");
      }
    } catch (error) {

      console.error("Error: ", error);
      throw error;
    }
  }

  async function getRecentSearches(accountId)
  {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/account/profile/getRecentSearches`,
        {
          accountid:accountId
        }
      );
      const { data } = res;
      const { status } = data;
      if (status === "success") {
        return data;
      } else {
        throw new Error("No previous searches found");
      }
    } catch (error) {
      throw error;
    }
  }
  
  async function insertRecentSearches(accountId, query, con)
  {
    const cun = country.filter((item)=> item.iso.toLowerCase() === con)[0].country;
    console.log(cun);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/account/profile/insertRecentSearches`,
        {
          accountid:accountId,
          query:query,
          country: cun
        }
      );
      const { data } = res;
      const { status } = data;
      if (status === "success") {
        return true;
      } else {
        throw new Error("Query not stored");
      }
    } catch (error) {
      throw error;
    }
  }
  const FetchData = async (text, code, codelocation, email, number) => {
    setIsLoading(true);
    setProgress(0);
    setSearchText(text + " with contact details");
  
    try {
      const response = await axios.post(
        "https://scrubber.temptrio.com/app/api/v1/searc",
        {
          query: text + " with contact details",
          code: code,
          location: codelocation,
          isEmail: email,
          isNumber: number,
        }
      );
      console.log(response.data);
      const { Data } = response.data;
      searchBalance(user.accountid, 1);
      setProgress(100);
      setScrdata(Data);
      await insertRecentSearches(user.accountid, text, codelocation);
    } catch (err) {
      console.log(err);
      setProgress(100);
      setScrdata({
        error: true,
        message: `${err.response.data.message}. Please try again later.`,
      });
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setProgress(0); // Reset progress value
      }, 1000);
    }
  };
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (progress <= 100) {
        setProgress(prevProgress => prevProgress + 5);
      } else {
        clearInterval(interval);
      }
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, [progress]);

  const loadingData = () => {
    return (
      <div>
        <p className="heading text-center">Collecting Data</p>
        <div className="d-flex justify-content-center w-100 mx-5"></div>
        <p className="text-center my-3">
          Please stay on this page while we load your data!
         
        </p>
        <p className="text-center fw-bold fs-4 my-3">
        {progress}%
        </p>
        <Progress animated value={progress} striped className="my-5" />
      </div>
    );
  };


  // const getData = async () => {
  //   const res = await axios.get("https://geolocation-db.com/json/");
  //   return(res.data?.country_code)
  // };
  const value = {
    user,
    register,
    login,
    logout,
    forgetpassword,
    resetpassword,
    updateProfile,
    changePassword,
    contactUs,
    emailValidate,
    emailResend,
    userMail,
    getPricing,
    getSubscriptionDetails,
    searchBalance,
    getCountryCode,
    isLoading,
    setIsLoading,
    loadingData,
    scrdata,
    setScrdata,
    FetchData,
    searchText, 
    setSearchText, 
    getRecentSearches,
    insertRecentSearches,
    getProfileDetails
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
