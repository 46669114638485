
import { Input, Button, Form, FormGroup, Label } from 'reactstrap';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useUserAuth } from '../Context/Context';
const Forgetpass = () => {
    const{actid} =useParams();
const {resetpassword} = useUserAuth();
const newpasswordRef = useRef(null);
const conpasswordRef = useRef(null);

const handleSubmit = (e) => {
    e.preventDefault();
    const newpass = newpasswordRef.current.value;
    const confirmpass = conpasswordRef.current.value;

resetpassword(newpass,confirmpass,actid)
}
  return(
    <div className=' vh-100 d-flex align-items-center justify-content-center'>

 <Form onSubmit={handleSubmit} className='w-100 mx-auto p-5 shadow-lg rounded-4' style={{maxWidth: "600px"}}>
 <h1 className="pb-3 " style={{ fontFamily: "sans-serif" }}>
            Update Password
          </h1>
          <FormGroup>
          <Label for="password" className=" fs-5">
                New Password
              </Label>
            <Input
              type="password"
              name="password"
              required
              style={{ border: "", boxShadow: "none", borderBottom: "2px solid black" }}
                      onFocus={() => {
                        newpasswordRef.current.style.border = "2px solid black";
                      }}
                      onBlur={() => {
                        newpasswordRef.current.style.border = "";
                        newpasswordRef.current.style.borderBottom = "2px solid black" // Reset the border when focus is lost
                      }}
                      className='py-3'
              id="password"
              placeholder="Enter your password"
              innerRef={newpasswordRef}
            />
          </FormGroup>
          <FormGroup>
          <Label for="password" className=" fs-5">
                Confirm Password
              </Label>
            <Input
              type="password"
              name="password"
              required
              style={{ border: "", boxShadow: "none", borderBottom: "2px solid black" }}
                      onFocus={() => {
                        conpasswordRef.current.style.border = "2px solid black";
                      }}
                      onBlur={() => {
                        conpasswordRef.current.style.border = "";
                        conpasswordRef.current.style.borderBottom = "2px solid black" // Reset the border when focus is lost
                      }}
                      className='py-3'
              id="password"
              placeholder="Enter your password"
              innerRef={conpasswordRef}
            />
          </FormGroup>
             <Button
                           className="px-5 py-2 fs-4 mt-3 mx-auto"
                           style={{
                             backgroundColor: "#0F52BA",
                             fontFamily: "sans-serif",
                             transition: "background-color 0.7s, color 0.2s, border 0s",
                           }}
                           disabled={false}
                           onMouseEnter={(e) => {
                             e.target.style.backgroundColor = "transparent";
                             e.target.style.color = "#0F52BA";
                             e.target.style.border = "2px solid #0d6efd";
                           }}
                           onMouseLeave={(e) => {
                             e.target.style.backgroundColor = "#0F52BA";
                             e.target.style.color = "white";
                             e.target.style.border = "";
                           }}
                         >
                           Submit
                         </Button>
             </Form>
    </div>
   
  )
}
export default Forgetpass;
    