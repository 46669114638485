import React, { useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input, FormGroup, Label } from "reactstrap";
import { AiOutlineClose } from "react-icons/ai";
import subscription from "../images/subscription.jpg"
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { Link } from "react-router-dom";
import { useUserAuth } from "../Context/Context";
function Upgrade({type, priceid, code}) {
  const {user} = useUserAuth();
  const [modal, setModal] = useState(false);
const countryRef= useRef();
  const toggle = () => setModal(!modal);

  //stripe integration
  async function stripeCheckout(sessionId){
    const stripe = await loadStripe(`${process.env.REACT_APP_KEY}`);
    stripe.redirectToCheckout({ sessionId:sessionId }).then(handleResult);
}

function createPayment(price_id,type,mode, email) {
    createCheckoutSession(price_id,type,mode, email).then((data)=>{
        console.log(data);
        if (data.sessionId) {
            stripeCheckout(data.sessionId)
        } else {
            console.log('wrong stripe credential')
        }
    });
}
const handleResult = (result)=> {
    console.log(result)
};


const createCheckoutSession = async function (price_id,type,mode, email) {
  console.log(price_id, type, mode, email);
    // var countryCode = document.getElementById('country').value
    // var subscriptionType = document.getElementById('durationTrack').value
    const response = await fetch(`${process.env.REACT_APP_BASE_URI}/account/subscription/paymentinit`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            createCheckoutSession: 1,
            package: price_id,
            mde: mode,
            customer_email: email,
            country: 85,
            subscriptionType: type
        }),
    });

    return response.json();
};

  return (
    <div>
      <button
        className="px-5 py-2 fs-4 mt-3 mx-auto but"
        onClick={toggle}
      >
        Upgrade
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="xl"
        className="rounded-5 "
      >
        <div className="d-flex justify-content-between mx-3 mt-4 ">
          <p className=" fs-3 ">Upgrade to {type}</p>
          <p className=" fs-4">
            <AiOutlineClose onClick={toggle} style={{ cursor: "pointer" }} />
          </p>
        </div>

        <ModalBody className=" mx-3">
          <div className="d-flex">
            <div className="my-auto">
           
                 <img src={subscription} className="d-none d-md-block w-100 float-start" style={{maxWidth:"500px"}} alt="" />
            </div>
            <div className="w-100 my-auto mx-lg-5">
              <p className="fs-4 fw-bolder my-4">
                Select the checkbox to proceed with subscription upgrade 
              </p>
              <Form onSubmit={(e) => { e.preventDefault(); createPayment(priceid, type, code, user.email); }}>
                <FormGroup>
          {/* <Input
          required
            className=""
            style={{ border: "", boxShadow: "none", borderBottom: "2px solid black" }}
                  onFocus={() => {
              countryRef.current.style.border = "2px solid black";
                  }}
                  onBlur={() => {
                    countryRef.current.style.border = "";
                    countryRef.current.style.borderBottom = "2px solid black" // Reset the border when focus is lost
                  }}
            type="select"
            name="country"
            id="country"
            innerRef={countryRef}
           
          > 
          <option>Select your country</option> 
          <option>India</option> 
          </Input> */}
          <p className="text-body-secondary fs-5">Are you sure you want to upgrade subscription?</p>
        </FormGroup>
               <FormGroup check>
          <Input
            type="checkbox"
            className="fs-5 border-dark"
            style={{ boxShadow: "none" }}
            required
          />{" "}
          <Label check>
  I agree to all statements in the{' '}
  <Link to="/conditions" className="text-decoration-underline">
    terms of services.
  </Link>
</Label>
        </FormGroup>
        <FormGroup>
        <Button
                  className="px-5 py-2 fs-5 mt-3 float-end"
                  style={{
                    backgroundColor: "#0F52BA",
                    fontFamily: "sans-serif",
                    transition: "background-color 0.5s, color 0.2s, border 0s",
                  }}
                  
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "transparent";
                    e.target.style.color = "#0F52BA";
                    e.target.style.border = "2px solid #0d6efd";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#0F52BA";
                    e.target.style.color = "white";
                    e.target.style.border = "";
                  }}
                >
                Proceed
                </Button>
        </FormGroup>
               
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Upgrade;
