import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
} from 'reactstrap';
import { useUserAuth } from '../Context/Context';
import withScrollToTop from './scrollToTop';

const PasswordChangeForm = () => {
  const {user, changePassword} = useUserAuth();
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your password change logic here
    if(passwordData.oldPassword === passwordData.newPassword)
    {
      toast.error("New password and old password are same")
    }
    else if (passwordData.newPassword === passwordData.confirmPassword) {
changePassword(passwordData.oldPassword, passwordData.newPassword, user.token)
    } else {
      toast.error("New password and confirm password do not match")
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit} className='mb-5 mt-2'>
        <FormGroup>
          <Label for="oldPassword" className="fs-6 fw-bolder">Old Password</Label>
          <Input
            type="password"
            name="oldPassword"
            id="oldPassword"
            style={{boxShadow: "none"}}
            className= "my-2 border "
            placeholder='Old Password'
            value={passwordData.oldPassword}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="newPassword" className="fs-6 fw-bolder">New Password</Label>
          <Input
            type="password"
            name="newPassword"
            id="newPassword"
            style={{boxShadow: "none"}}
            className= "my-2"
            placeholder='New Password'
            value={passwordData.newPassword}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="confirmPassword" className="fs-6 fw-bolder">Confirm Password</Label>
          <Input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            style={{boxShadow: "none"}}
            className= "my-2"
            placeholder='Confirm Password'
            value={passwordData.confirmPassword}
            onChange={handleChange}
            required
          />
        </FormGroup>

        <Button type='submit' color="primary" className="px-5 py-2 float-end fs-5 mt-3"
         style={{
          fontFamily: "sans-serif",
          transition: "background-color 0.7s, color 0.2s, border 0s", // Add transition properties for border
        }}
           onMouseEnter={(e) => {
            e.target.style.backgroundColor = "transparent";
            e.target.style.color = "#0d6efd"; // Change text color to white
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "";
            e.target.style.color = ""; // Restore the original text color
          }}
          >
            Change Password
          </Button>
      </Form>
    </Container>
  );
};

export default withScrollToTop(PasswordChangeForm);
