import { Container, Table, Button } from "reactstrap";
import withScrollToTop from "../components/scrollToTop";
import { useUserAuth } from "../Context/Context";
import { useEffect, useState } from "react";
import { FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";
const Resultpage = () => {
  const [searches, setSearches] = useState(null); // Initialize with an empty array
  const { getRecentSearches, user } = useUserAuth();

  useEffect(() => {
    if (user) {
      getRecentSearches(user.accountid).then((res) => {
        setSearches(res.query || []); // Ensure that res.query is an array or default to an empty array
      });
    }
  }, [user]);

  return (
    <Container className="min-vh-100 my-5">
      <p className="heading text-center my-5">My Results</p>
      <div>
        <p className="my-5 fs-3">Recent Searches</p>
        {user && searches ? (
          <>
            {searches.length !== 0 ? (
              <Table striped responsive className="rounded-3 w-100 border border-dark-subtle border-1 ">
                <thead className="border-bottom border-dark">
                  <tr>
                    <th>Query</th>
                    <th>Country</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {searches.map((item) => (
                    <tr key={item.id}>
                      <td>{item.query}</td>
                      <td>{item.country}</td>
                      <td>{item.date}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <>
                <div className="d-flex justify-content-center">
                  <div>
                    <p className="text-capitalize fs-4 mb-5">
                      You do not have any Recent Searches Yet!
                    </p>
                    <div className="d-flex justify-content-center">
                    <Button
                      color="dark"
                      tag={Link}
                      to="/scrape"
                      className={`px-md-5 py-md-3 fs-5 my-5 my-md-0 but`}
                      outline
                    >
                      Get Started
                    </Button>
                    </div>
                    
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center fs-3 ">
              <div>
                <p>Loading...</p>
                <FadeLoader color="#000000" className="mx-auto" />
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default withScrollToTop(Resultpage);
