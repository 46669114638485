import {Container} from "reactstrap"
import { useParams, } from "react-router-dom";
import { useEffect } from "react";
import { useUserAuth } from "../Context/Context";
import withScrollToTop from "./scrollToTop";
const Emailverify = () => {
    const {emailValidate} = useUserAuth();
    const {key} = useParams();

    useEffect(() => {
emailValidate(key);
    }, [key])
return(
    <>
    <Container className="min-vh-100 my-5 pt-5 text-center">
        <p className="display-4">Please wait while we verify your Email.</p>
        <div class="spinner-border text-primary m-5" style={{width: "3rem", height: "3rem"}} role="status">
  <span class="visually-hidden">Loading...</span>
</div>

    </Container>
    </>
)
}
export default withScrollToTop(Emailverify); 