import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Scrapelanding from "./pages/scrapeLanding";
import Navvbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Signup from "./pages/Signup";
import Footer from "./components/Footer";
import ScrapeData from "./components/Scrape";
import Profile from "./pages/Myprofile";
import Subscription from "./pages/Subscription";
import Login from "./pages/Signin";
import Pricing from "./pages/Pricing";
import Conditions from "./pages/Conditions";
import Privacy from "./pages/Privacy";
import About from "./pages/About";
import { UserContextProvider } from "./Context/Context";
import { Container } from "reactstrap";
import Contact from "./components/Contact";
import Forgetpass from "./components/forgotpassword";
import AuthPvtRoute from "./routes/Pvtroute";
import Verify from "./components/Verification";
import Emailverify from "./components/Emailverify";
import Thankyou from "./pages/Thankyou";
import Resultpage from "./pages/Myresults";
function App() {

  return (
    <Container
      fluid
      className="App px-0"
    >
      <ToastContainer position="top-center" autoClose={3000}/>
      <UserContextProvider>
        <Navvbar/>
        <div style={{paddingTop:"70px"}}>
        <Routes>
          <Route path="/" element={<Scrapelanding />} />
          <Route path="/signup" element={<Signup />}/>
          <Route path="/signin" element={<Login />}></Route>
          <Route path="/signin/verification/:key" element={<Verify />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/about" element={<About />} />
          <Route
            path="myprofile"
            element={
              <AuthPvtRoute>
                {" "}
                <Profile />{" "}
              </AuthPvtRoute>
            }
          />
          {/* <Route
            path="/subscriptiondetails"
            element={
              <AuthPvtRoute>
                {" "}
                <Subscription />{" "}
              </AuthPvtRoute>
            }
          /> */}
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/scrape"
            element={
              <AuthPvtRoute>
                {" "}
                <ScrapeData/>{" "}
              </AuthPvtRoute>
            }
          />
          <Route path="/conditions" element={<Conditions />} />
          <Route path="/privacy_policy" element={<Privacy />} />
          <Route path="/subscription_success" element={<Thankyou/>} />
           <Route path="/myresult" element={<Resultpage/>} /> 
          <Route path="/resetpassword/:actid" element={<Forgetpass />} />
          <Route path="/emailverify/:key" element={<Emailverify/>} />
        </Routes>
        </div>
      
      </UserContextProvider>
      <Footer />
    </Container>
  );
}

export default App;
