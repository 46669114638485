import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip
} from "reactstrap";
import { Link} from "react-router-dom";
import {
  AiOutlineClose,
  AiFillGoogleSquare,
  AiFillFacebook,
} from "react-icons/ai";
import Lottie from 'lottie-react';
import forgetanimation  from "../images/forgetanimation.json"
import { useUserAuth } from "../Context/Context";
import withScrollToTop from "../components/scrollToTop";

const Login = () => {
  const{login, forgetpassword} = useUserAuth();
  const [googleTooltipOpen, setGoogleTooltipOpen] = useState(false);
  const [facebookTooltipOpen, setFacebookTooltipOpen] = useState(false);
 

  const toggleGoogleTooltip = () => {
    setGoogleTooltipOpen(!googleTooltipOpen);
  };

  const toggleFacebookTooltip = () => {
    setFacebookTooltipOpen(!facebookTooltipOpen);
  };
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const forgetemail = useRef();

  const handleforgetmail = (e) => {
    e.preventDefault();
    forgetpassword(forgetemail.current.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initialize a new errors object.
    const newErrors = {};

    if (!emailRef.current.value) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(emailRef.current.value)) {
      newErrors.email = "Invalid email format";
    }
    if (!passwordRef.current.value) {
      newErrors.password = "Password is required";
    }

    // Set the errors state to the new errors object.
    setErrors(newErrors);

    setIsSubmit(true);
  };
  const handleChange = (refName) => {
    setErrors((prevErrors) => ({ ...prevErrors, [refName]: "" }));
  };


  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const email= emailRef.current.value;
      const password = passwordRef.current.value;
      login(email, password);
      
    }
    setIsSubmit(false);
  },[isSubmit]);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <div className="d-flex justify-content-center  align-items-center min-vh-100 py-5">
      {/* <div className = "w-50"> */}
      <Form
        className="p-5 rounded-5 shadow-lg text-dark w-100 max-width-400"
      >
        <div className="d-md-flex justify-content-between mb-3">
          <h1 className="pb-2 my-auto" style={{ fontFamily: "sans-serif" }}>
            Login
          </h1>
          <Link to="/signup" className="my-auto fs-6 text-dark">
            Register as a new User?
          </Link>
        </div>
        <FormGroup>
          <Label for="email" className=" fs-5">
            Email
          </Label>
          <Input
          required
            type="email"
            name="email"
            id="email"
            style={{
              border: "",
              boxShadow: "none",
              borderBottom: "1px solid black",
            }}
            onFocus={() => {
              emailRef.current.style.border = "1px solid black";
            }}
            onBlur={() => {
              emailRef.current.style.border = "";
              emailRef.current.style.borderBottom = "1px solid black"; // Reset the border when focus is lost
            }}
            className="py-3"
            placeholder="Enter your email"
            invalid={!!errors.email}
            innerRef={emailRef}
            onChange={() => handleChange("email")}
          />
          {errors.email && <FormFeedback>Please enter your email</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label for="password" className=" fs-5">
            Password
          </Label>
          <Input
          required
            type="password"
            name="password"
            style={{
              border: "",
              boxShadow: "none",
              borderBottom: "1px solid black",
            }}
            onFocus={() => {
              passwordRef.current.style.border = "1px solid black";
            }}
            onBlur={() => {
              passwordRef.current.style.border = "";
              passwordRef.current.style.borderBottom = "1px solid black"; // Reset the border when focus is lost
            }}
            className="py-3"
            id="password"
            placeholder="Enter your password"
            invalid={!!errors.password}
            innerRef={passwordRef}
            onChange={() => handleChange("password")}
          />
          {errors.password && (
            <FormFeedback>Please enter your password</FormFeedback>
          )}
        </FormGroup>
        <div className="">
          <Button
          type="submit"
          onClick={handleSubmit}
            color="primary"
            className="px-5 py-2 fs-4  mt-3"
            style={{
              fontFamily: "sans-serif",
              transition: "background-color 0.7s, color 0.2s, border 0s", // Add transition properties for border
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.color = "#0d6efd"; // Change text color to white
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "";
              e.target.style.color = ""; // Restore the original text color
            }}
          >
            Login
          </Button>
          <div className="float-md-end mt-3">
            <Button
              className="my-auto fs-5 text-secondary border-0 bg-transparent"
              outline
              onClick={toggle}
            >
              Forgot Password?
            </Button>
            <Modal
              isOpen={modal}
              toggle={toggle}
              centered
              size="xl"
              className="rounded-5 "
            >
              <div className="d-flex justify-content-between mx-3 mt-4 ">
                <p className=" fs-3 fw-bold">Reset Password</p>
                <p className=" fs-4">
                  <AiOutlineClose
                    onClick={toggle}
                    style={{ cursor: "pointer" }}
                  />
                </p>
              </div>

              <ModalBody className="my-5 mx-3">

              <div className="d-flex">
        <div>
         <Lottie animationData={forgetanimation}
                       className=" d-none d-md-block w-75 float-start"
                 />      
       </div>
       <div className='w-100 my-auto'>
         <p className='fs-4 fw-bolc'>Enter your email to get the reset password link</p>
         <Form onSubmit={handleforgetmail}>
         <Input
         type='email'
         className='border-0 border-bottom border-dark border-3 my-3'
         style={{boxShadow: "none"}}
       required
       innerRef={forgetemail}
         />
         <Button
         type="submit"
         color="primary"
                       className="px-5 py-2 fs-4 mt-3 mx-auto"
                       style={{
                        fontFamily: "sans-serif",
                        transition: "background-color 0.7s, color 0.2s, border 0s", // Add transition properties for border
                      }}
                       onMouseEnter={(e) => {
                         e.target.style.backgroundColor = "transparent";
                         e.target.style.color = "#0F52BA";
                         e.target.style.border = "2px solid #0d6efd";
                       }}
                       onMouseLeave={(e) => {
                         e.target.style.backgroundColor = "#0F52BA";
                         e.target.style.color = "white";
                         e.target.style.border = "";
                       }}
                     >
                       Send Link
                     </Button>
         </Form>
         
       </div>
           </div>

              </ModalBody>
            </Modal>
          </div>
        </div>
        <div className="mt-5">
        <p>
      Or Login With{" "}
      <AiFillGoogleSquare
        className="text-danger fs-2"
        style={{ cursor: "pointer" }}
        id="googleIcon"
        onMouseEnter={toggleGoogleTooltip}
        onMouseLeave={toggleGoogleTooltip}
      />
      <AiFillFacebook
        className="text-primary fs-2"
        style={{ cursor: "pointer" }}
        id="facebookIcon"
        onMouseEnter={toggleFacebookTooltip}
        onMouseLeave={toggleFacebookTooltip}
      />
      <Tooltip target="googleIcon" isOpen={googleTooltipOpen}>
        Google 
      </Tooltip>
      <Tooltip target="facebookIcon" isOpen={facebookTooltipOpen}>
        Facebook
      </Tooltip>
    </p>
        </div>
      </Form>
     
      </div>
     
    // </div>
  );
};

export default withScrollToTop(Login);
