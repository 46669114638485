import React, { useEffect } from 'react';

function withScrollToTop(Component) {
  return function ScrollToTopWrapper(props) {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return <Component {...props} />;
  };
}

export default withScrollToTop;
