import { Container } from "reactstrap";
import { BsFacebook, BsInstagram, BsGithub, BsLinkedin } from "react-icons/bs";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <Container
        fluid
        className="px-md-5 ps-2 pt-5 text-white"
        style={{ backgroundColor: "#1D262E" }}
      >
        <div className="d-md-flex justify-content-between align-items-center">
          <div className="fs-2 fw-bold pb-2">Grab.io</div>
          <p className="me-3">Follow us on:  </p>
        </div>

        <div className="d-md-flex justify-content-between align-items-center flex-row-reverse">
        <div className="d-flex justify-md-content-end pb-2 my-3 my-md-0">
            
            <BsFacebook className="fs-3 me-3 zoom text-light rounded-circle" style={{backgroundColor:"blue"}}  />
            <BsInstagram className="fs-3 mx-3 zoom bg-transparent text-instagram rounded-circle" />
            <BsLinkedin className="fs-3 mx-3 zoom text-light bg-primary rounded-circle" />
            <BsGithub className="fs-3 mx-3 zoom text-white rounded-circle" />
          </div>
          <div className="d-md-flex ">
            <Link
              to="/conditions"
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              <p className="pe-3 mb-0 text-white my-3 my-md-0">Terms & Conditions</p>
            </Link>
            <Link
              to="/privacy_policy"
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              <p className="pe-3 mb-0 text-white my-3 my-md-0">Privacy Policy</p>
            </Link>
          </div>
          
        </div>
        <div className="text-md-center pt-3 py-2" style={{fontSize:"12px"}}>
        © 2023 Temptrio. All Rights Reserved | Powered by Codeulas
        </div>
      </Container>
    </>
  );
};

export default Footer;
