import {
  Row,
  Col,
  Card,
  Button,
  CardTitle,
  CardBody,
  ButtonGroup,
  Badge,
  Container,
} from "reactstrap";
import { FadeLoader } from "react-spinners";
import { TiTick } from "react-icons/ti";
import { useEffect, useState } from "react";
import { useUserAuth } from "../Context/Context";
import { BiDollar, BiRupee } from "react-icons/bi";
import { Link } from "react-router-dom";
import Upgrade from "../components/upgradeModal";
import withScrollToTop from "../components/scrollToTop";
const Pricing = () => {
  const { getPricing, user, getSubscriptionDetails, searchBalance } =
    useUserAuth();
  const [getprice, setGetPrice] = useState();
  const [country, setCountry] = useState(false);
  const [subDetails, setSubDetails] = useState(null);
  const [balance, setBalance] = useState();
  const [selected, setSelected] = useState("0");
  const [buttonselect, setButtonSelect] = useState(1);

  useEffect(() => {
    getPricing().then((res) => {
      setGetPrice(res);
    });
  }, []);
  useEffect(() => {
    if (user) {
      searchBalance(user.accountid, 0).then((res) => setBalance(res));
      getSubscriptionDetails(user.accountid).then((res) => {
        if (res !== null) {
          setSelected(res.subscription?.package_id);
          setSubDetails(res.subscription);
        }
      });
    }
  }, [user]);

  return (
    <div className="min-vh-100 d-flex justify-content-center">
      <div className="w-100 mx-md-5">
        <div className="my-5">
          <p className="text-center heading">Membership</p>
        </div>
        {getprice ? (
          <>
            <div className="my-4 d-flex justify-content-center">
              <ButtonGroup>
                <Button
                  color="dark"
                  className={`border-0 px-4 py-3 fs-5 bg-transparent border-dark border-2 text-dark  ${
                    buttonselect === 1 ? `border-bottom` : `border-0`
                  }`}
                  outline
                  onClick={() => setButtonSelect(1)}
                  active={buttonselect === 1}
                >
                  Monthly
                </Button>

                <Button
                  color="dark"
                  className={`border-0 px-4 py-3 fs-5 bg-transparent border-dark border-2 text-dark ${
                    buttonselect === 2 ? `border-bottom` : `border-0`
                  }`}
                  outline
                  onClick={() => setButtonSelect(2)}
                  active={buttonselect === 2}
                >
                  Yearly
                </Button>
              </ButtonGroup>
            </div>
            <Container data-aos="zoom-in" className="">
              <Row
                className="my-5 pb-5 w-100 d-flex mx-auto"
                style={{ maxWidth: "1000px" }}
              >
                <Col
                  lg={6}
                  md={10}
                  className="d-flex justify-content-center align-items-center my-3 my-lg-0"
                >
                  <Card className="px-3 pt-4 pb-5 shad w-100 rounded-2 ">
                    <CardBody className="">
                      <CardTitle className="fs-4 border-bottom border-dark-subtle pb-2">
                        Free
                      </CardTitle>
                      <p className="text-center fs-2 text-dark my-4 fw-bold">
                        <span className="fs-5">{country ? <BiRupee /> : <BiDollar />}</span>
                        0
                      </p>

                      <div className="my-3">
                        <p className="fs-6">
                          <TiTick className="fs-4 text-white me-2 p-1 bg-primary rounded-circle" />{" "}
                          1 user
                        </p>
                        <p className="fs-6">
                          <TiTick className="fs-4 text-white me-2 p-1 bg-primary rounded-circle" />{" "}
                          5 free searches
                        </p>
                      </div>
                    </CardBody>
                    {selected === "0" && user ? (
                      <>
                        <button
                          className="px-5 py-2 fs-5 mt-3 mx-auto selected"
                          disabled={false}
                        >
                          Selected
                        </button>
                      </>
                    ) : (
                      <>
                        {subDetails ? (
                          <>
                            <Button
                              className="px-5 py-2 fs-5 mt-3 mx-auto"
                              style={{
                                backgroundColor: "#0F52BA",
                                fontFamily: "sans-serif",
                                transition:
                                  "background-color 0.5s, color 0.2s, border 0s",
                              }}
                              disabled={true}
                            >
                              Upgrade
                            </Button>
                          </>
                        ) : (
                          <>
                            <Link to="/signin" className="mx-auto">
                              <button className="px-5 py-2 fs-5 mt-3 but">
                                Upgrade
                              </button>
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                <Col
                  lg={6}
                  md={10}
                  className="d-flex justify-content-center align-items-center my-3 my-lg-0"
                >
                  <Card className="px-3 pt-4 pb-5 shad w-100 rounded-2">
                    <CardBody>
                      <CardTitle className="fs-4 border-bottom border-dark-subtle pb-2">
                        Professional
                      </CardTitle>
                      {buttonselect === 1 ? (
                        <p className="text-center fs-3 text-dark mt-4 mb-2 fw-bold">
                          {country ? (
                            <>
                              <span className="fs-5">
                                {" "}
                                <BiRupee />
                              </span>

                              {getprice.package[0].monthly_india}
                            </>
                          ) : (
                            <>
                              <span className="fs-5">
                                {" "}
                                <BiDollar />
                              </span>

                              {getprice.package[0].monthly}
                            </>
                          )}
                          <span className="fs-6">/monthly</span>
                        </p>
                      ) : (
                        <>
                          <p className="text-center fs-3 text-dark mt-4 mb-2 fw-bold">
                            {country ? (
                              <>
                                <span className="fs-5">
                                {" "}
                                <BiRupee />
                              </span>
                                {getprice.package[0].yearly_india}
                              </>
                            ) : (
                              <>
                                <span className="fs-5">
                                {" "}
                                <BiDollar />
                              </span>
                                {getprice.package[0].annual}
                              </>
                            )}
                            <span className="fs-6">/yearly</span>
                          </p>
                        </>
                      )}
                      <div className="my-3">
                        <p className="fs-6">
                          <TiTick className="fs-4 text-white me-2 p-1 bg-primary rounded-circle" />{" "}
                          1 user
                        </p>
                        <p className="fs-6">
                          <TiTick className="fs-4 text-white me-2 p-1 bg-primary rounded-circle" />{" "}
                          5 free searches
                        </p>
                      </div>
                    </CardBody>

                    {selected === "1" ? (
                      <>
                        {buttonselect === 1 &&
                        subDetails.duration === "0" &&
                        balance !== "0" ? (
                          <button
                            className="px-5 py-2 fs-5 mt-3 mx-auto selected"
                            disabled={false}
                          >
                            Selected
                          </button>
                        ) : (
                          <>
                            {subDetails.duration !== "1" ? (
                              <div className="d-flex justify-content-center">
                                <Upgrade
                                  type={`Professional ${
                                    buttonselect === 1
                                      ? `(monthly)`
                                      : `(yearly)`
                                  }`}
                                  priceid={`${
                                    buttonselect === 1
                                      ? `${
                                          country
                                            ? `${getprice.package[0].stripe_monthly_india}`
                                            : `${getprice.package[0].stripe_monthly}`
                                        }`
                                      : `${
                                          country
                                            ? `${getprice.package[0].stripe_yearly_india}`
                                            : `${getprice.package[0].stripe_yearly}`
                                        }`
                                  }`}
                                  code={0}
                                />
                              </div>
                            ) : (
                              <>
                                <button
                                  className="px-5 py-2 fs-5 mt-3 mx-auto selected"
                                  disabled={false}
                                >
                                  Selected
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {user ? (
                          <>
                            {subDetails ? (
                              <>
                                <Button
                                  className="px-5 py-2 fs-5 mt-3 mx-auto"
                                  style={{
                                    backgroundColor: "#0F52BA",
                                    fontFamily: "sans-serif",
                                    transition:
                                      "background-color 0.5s, color 0.2s, border 0s",
                                  }}
                                  disabled={true}
                                >
                                  Upgrade
                                </Button>
                              </>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <Upgrade
                                  type={`Professional ${
                                    buttonselect === 1
                                      ? `(monthly)`
                                      : `(yearly)`
                                  }`}
                                  priceid={`${
                                    buttonselect === 1
                                      ? `${
                                          country
                                            ? `${getprice.package[0].stripe_monthly_india}`
                                            : `${getprice.package[0].stripe_monthly}`
                                        }`
                                      : `${
                                          country
                                            ? `${getprice.package[0].stripe_yearly_india}`
                                            : `${getprice.package[0].stripe_yearly}`
                                        }`
                                  }`}
                                  code={0}
                                />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <Link to="/signin" className="mx-auto">
                              <button
                                className="px-5 py-2 fs-5 mt-3 but"
                                disabled={false}
                              >
                                Upgrade
                              </button>
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </Card>
                </Col>
                {/* <Col
                  lg={4}
                  md={10}
                  className="d-flex justify-content-center align-items-center my-3 my-lg-0"
                >
                  <Card className="px-3 py-5 shadow-lg w-100 rounded-5">
                    <CardBody>
                      <CardTitle className="fs-3 border-bottom border-dark-subtle fw-bold pb-2">
                        Lifetime
                      </CardTitle>

                      <p className="text-center fs-2 text-secondary my-2">
                        {country ? (
                          <>
                            <BiRupee />
                            {getprice.package[1].monthly_india}
                          </>
                        ) : (
                          <>
                            <BiDollar />
                            {getprice.package[1].monthly}
                          </>
                        )}
                      </p>

                      <div className="my-3">
                        <p className="fs-5">
                          <TiTick className="fs-4" /> 1 user
                        </p>
                        <p className="fs-5">
                          <TiTick className="fs-4" /> 5 free searches
                        </p>
                      </div>
                    </CardBody>
                    {selected === "4" ? (
                      <>
                        {subDetails.duration === "2" ? (
                          <button
                            className="px-5 py-2 fs-4 mt-3 mx-auto selected"
                            disabled={false}
                          >
                            Selected
                          </button>
                        ) : (
                          <>
                            <button
                              className="px-5 py-2 fs-4 mt-3 mx-auto but"
                              disabled={false}
                            >
                              Upgrade
                            </button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {user ? (
                          <div className="d-flex justify-content-center">
                            <Upgrade
                              type="Lifetime"
                              priceid={`${
                                buttonselect === 1
                                  ? `${
                                      country
                                        ? `${getprice.package[1].stripe_monthly_india}`
                                        : `${getprice.package[1].stripe_monthly}`
                                    }`
                                  : `${
                                      country
                                        ? `${getprice.package[1].stripe_yearly_india}`
                                        : `${getprice.package[1].stripe_yearly}`
                                    }`
                              }`}
                              code={1}
                            />
                          </div>
                        ) : (
                          <>
                            <Link to="/signin" className="mx-auto">
                              <button
                                className="px-5 py-2 fs-4 mt-3 but"
                                disabled={false}
                              >
                                Upgrade
                              </button>
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </Card>
                </Col> */}
              </Row>
            </Container>
          </>
        ) : (
          <div className="d-flex justify-content-center fs-3 ">
            <div>
              <p>Loading...</p>
              <FadeLoader color="#000000" className="mx-auto" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withScrollToTop(Pricing);
