import React, { useContext, useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import uploadanimation from "../images/uploadanimation.json";
import {
  CardHeader,
  Container,
  Card,
  CardBody,
  Label,
  Button,
  Input,
  Form,
  Row,
  FormGroup,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { TbFileUpload } from "react-icons/tb";
import coverphoto from "../images/coverphoto.png";
import dummypic from "../images/dummypic.png";
import { MdOutlinePhotoCamera, MdOutlineUploadFile } from "react-icons/md";
import PasswordChangeForm from "../components/changepassword";
import { hover } from "@testing-library/user-event/dist/hover";
import { useUserAuth } from "../Context/Context";
import withScrollToTop from "../components/scrollToTop";
import Subscription from "./Subscription";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
const Profile = () => {
  const { user, updateProfile, getCountryCode, getProfileDetails } =
    useUserAuth();
  const navigate = useNavigate();
  const [country, setCountry] = useState();
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const phoneRef = useRef(null);
  const addRef = useRef(null);

  const [active, setActive] = useState(true);
  const [selected, setSelected] = useState(1);
  const [modal, setModal] = useState(false);
  const [upload, setUpload] = useState(null);
  const imgRef = useRef(null);
  const toggleModal = () => {
    setModal(!modal);
    setUpload(null);
  };
  useEffect(() => {
    getCountryCode().then((res) => setCountry(res));
  }, []);
 

  useEffect(() => {
    if(user)
    {
      getProfileDetails(user.accountid);
    }
  }, [upload]);


  const toggle = () => setActive(!active);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!active) {
      const firstname = firstnameRef.current.value;
      const lastname = lastnameRef.current.value;
      const email = user.email;
      const phone = phoneRef.current.value;
      console.log(addRef.current.value);
      const address = addRef.current.value
        ? country.find((item) => item.name === addRef.current.value).id
        : country.find((item) => item.name === user.country).id;
      console.log(address);
      updateProfile(firstname, lastname, email, phone, address);
    }
    toggle();
  };
  const handleChange = () => {
    if (imgRef.current.value) {
      setUpload(imgRef.current.value);
    }
  };
  
  return (
    <div style={{ backgroundColor: "#F5F5F5" }} className="min-vh-100 my-5">
      {user && (
        <Container>
          <Row>
            <Col md={4} className="px-0">
              <Card
                className="position-sticky pb-5 border-0 bg-transparent me-md-5"
                style={{ top: "10%" }}
              >
                <div className="pt-5 pb-3 d-flex justify-content-center">
                  <div
                    className="d-md-none"
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <img
                      src={user.profile_image !== "" ? user.profile_image : dummypic}
                      alt=""
                      className="rounded-circle border border-dark-subtle border-5 image-fluid"
                      style={{
                        width: "180px",
                        height: "180px",
                        objectFit: "cover",
                      }}
                    />
                    <MdOutlinePhotoCamera
                      onClick={toggleModal}
                      className="text-dark bg-light-subtle p-2 rounded-circle"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "20%",
                        left: "90%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "3rem", // Adjust the font size as needed
                      }}
                    />
                  </div>
                  <img
                    src={user.profile_image !== "" ? user.profile_image : dummypic}
                    alt=""
                    className="rounded-circle border border-dark-subtle border-5 image-fluid d-none d-md-block"
                    style={{
                      width: "180px",
                      height: "180px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="text-center mb-2 fw-bold fs-5">
                  {user.first_name} {user.last_name}
                </div>
                <div className="text-center mb-4 fw-bolder fs-6">
                  {user.email}
                </div>
                <div className="mx-auto mt-2 mb-2">
                  <button
                    className={`px-5 ${
                      selected === 1
                        ? "btn_center_green py-3"
                        : "btn btn-outline bg-light fw-bolder text-dark rounded-pill border-0 "
                    }`}
                    onClick={() => setSelected(1)}
                  >
                    Edit Profile
                  </button>
                </div>
                <div className="mx-auto mt-2 mb-2">
                  <button
                    className={`px-3 ${
                      selected === 2
                        ? "btn_center_green py-3"
                        : "btn btn-outline bg-light fw-bolder text-dark rounded-pill border-0 "
                    }`}
                    onClick={() => setSelected(2)}
                  >
                    Change Password
                  </button>
                </div>
                <div className="mx-auto mt-2 mb-2">
                  <button
                    className={`px-5 ${
                      selected === 3
                        ? "btn_center_green py-3"
                        : "btn btn-outline bg-light fw-bolder text-dark rounded-pill  border-0"
                    }`}
                    onClick={() => setSelected(3)}
                  >
                    Subscription
                  </button>
                </div>

                {/* btn_center can be used for bluue button */}
              </Card>
            </Col>
            <Col md={8}>
              <Card className=" pb-5">
                <div style={{ position: "relative" }}>
                  <img
                    src={coverphoto}
                    alt=""
                    className="image-fluid h-100 w-100"
                    style={{ maxHeight: "300px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-25%",
                      left: "85%",
                      transform: "translateX(-50%)",
                    }}
                  >
                    <img
                      src={user.profile_image !== "" ? user.profile_image : dummypic}
                      alt=""
                      className="rounded-circle border border-dark-subtle border-5 image-fluid d-none d-md-block"
                      style={{
                        width: "180px",
                        height: "180px",
                        objectFit: "cover",
                      }}
                    />
                    <MdOutlinePhotoCamera
                      onClick={toggleModal}
                      className="text-dark bg-light-subtle p-2 rounded-circle d-none d-md-block"
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "20%",
                        left: "88%",
                        transform: "translate(-50%, -50%)",
                        fontSize: "3rem", // Adjust the font size as needed
                      }}
                    />
                  </div>
                </div>

                <CardBody className="px-md-5 py-2">
                  <div
                    className={`mt-4 ${selected === 1 ? "d-block" : "d-none"}`}
                  >
                    <h3 className="pt-2 pb-3 fw-bolder">
                      Personal Information
                    </h3>
                    <Form onSubmit={handleSubmit} className="mx-md-5">
                      <Label className=" fs-6 fw-bolder ">First Name</Label>
                      <Input
                        type="text"
                        name="name"
                        required
                        className={`my-2 ${
                          !active ? "border border-dark" : ""
                        }`}
                        style={{ boxShadow: "none" }}
                        placeholder="Enter your first name"
                        disabled={active}
                        defaultValue={user.first_name}
                        innerRef={firstnameRef}
                      />
                      <Label className=" fs-6 fw-bolder ">Last Name</Label>
                      <Input
                        type="text"
                        name="name"
                        required
                        className={`my-2 ${
                          !active ? "border border-dark" : ""
                        }`}
                        style={{ boxShadow: "none" }}
                        placeholder="Enter your last name"
                        disabled={active}
                        defaultValue={user.last_name}
                        innerRef={lastnameRef}
                      />
                      <Label className="mt-2 fs-6 fw-bolder ">Email</Label>
                      <Input
                        type="email"
                        name="email"
                        required
                        className={`my-2 ${
                          !active ? "border border-dark" : ""
                        }`}
                        style={{ boxShadow: "none" }}
                        placeholder="Enter your email"
                        disabled
                        defaultValue={user.email}
                      />
                      <Label className="mt-2 fs-6 fw-bolder ">
                        Mobile Number
                      </Label>
                      <Input
                        type="number"
                        name="phone"
                        required
                        className={`my-2 ${
                          !active ? "border border-dark" : ""
                        }`}
                        style={{ boxShadow: "none" }}
                        placeholder="Enter your mobile number"
                        disabled={active}
                        defaultValue={user.mobile}
                        innerRef={phoneRef}
                      />

                      <Label className="mt-2 fs-6 fw-bolder ">Country</Label>
                      <Input
                        type="select"
                        name="country"
                        className={`my-2 ${
                          !active ? "border border-dark" : ""
                        }`}
                        style={{ boxShadow: "none" }}
                        disabled={active}
                        innerRef={addRef}
                      >
                        <option value="" disabled selected>
                          {user.country}
                        </option>
                        {country &&
                          country.map((item, index) => (
                            <option key={index}>{item.name}</option>
                          ))}
                      </Input>
                      <div className="w-100 d-flex justify-content-end">
                        <Button
                          type="submit"
                          className={` mt-5 px-5 py-2 fs-5 text-white zoom ${
                            active ? "bg-primary" : "bg-success"
                          }`}
                          color={active ? `primary` : `success`}
                        >
                          {active ? `Update` : "Save"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                  <div
                    className={` mt-4 ${selected === 2 ? "d-block" : "d-none"}`}
                  >
                    <h3 className="pt-2 pb-3 fw-bolder">Change Password</h3>
                    <PasswordChangeForm />
                  </div>
                  <div
                    className={` mt-4 ${selected === 3 ? "d-block" : "d-none"}`}
                  >
                    <h3 className="pt-2 pb-3 fw-bolder">My Subscription</h3>
                    <Subscription />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      {/* Modal for add image */}

      <Modal isOpen={modal} toggle={toggleModal} centered>
        <div className="d-flex justify-content-between mx-3 mt-4 ">
          <p className=" fs-4 mb-0 fw-bolder">Update Profile Picture</p>
          <p className=" fs-4 mb-0">
            <AiOutlineClose
              onClick={toggleModal}
              style={{ cursor: "pointer" }}
            />
          </p>
        </div>
        <hr />
        <ModalBody className="px-5 pt-3 pb-4">
        <form
  action={`${process.env.REACT_APP_BASE_URI}/account/profile/profileImageUpdate`}
  method="post"
  encType="multipart/form-data"
  // onSubmit={handleImageUpload}
>
      
   <Input
        type="text"
        name="accountid"
        value={user && user.accountid}
        className="d-none"
      />
     
      <Label className="d-flex justify-content-center">
        <MdOutlineUploadFile
          className={`${upload === null ? ' my-2 text-secondary' : 'd-none'}`}
          style={{ fontSize: "4rem", cursor: "pointer" }}
        />
         
      <Input
          required
          type="file"
          name="profile_image"
          accept="image/*"
          className="visually-hidden"
          innerRef={imgRef}
          onChange={handleChange}
        />
      </Label>
    {upload === null ? <p className="text-center">Click to Upload Image</p> : <>
    <Container className="d-flex justify-content-center mb-4">
        <div>
          <Lottie
            loop="false"
            animationData={uploadanimation}
            className=""
            style={{ width: "100px", height: "100px" }}
          />
          File Selected
        </div>
      </Container>
    </>}
      
 

  <Button
        type="submit"
        className={`${upload === null ? 'd-none':'px-3 py-2 fs-5 mt-3 float-end'}`}
        style={{
          backgroundColor: "#4CAF50",
          color: "white",
          fontFamily: "sans-serif",
          transition: "background-color 0.5s, color 0.2s, border 0s",
        }}
        onMouseEnter={(e) => {
          e.target.style.backgroundColor = "transparent";
          e.target.style.color = "#4CAF50";
          e.target.style.border = "2px solid #4CAF50";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = "#4CAF50";
          e.target.style.color = "white";
          e.target.style.border = "";
        }}
      >
        Upload
      </Button>
</form>

        </ModalBody>
      </Modal>
    </div>
  );
};
export default withScrollToTop(Profile);
