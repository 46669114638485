import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function AuthPvtRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const auth = localStorage.getItem("userlogin");
   

    if (!auth) {
      setIsAuthenticated(false);
    }
  }, []);



  return isAuthenticated ? children : <Navigate to="/signin" />;
}
