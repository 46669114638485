import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { FaRegQuestionCircle } from "react-icons/fa";

const questions = [
  {
    question:"What is Grab.io?",
    answer: "Grab.io is an efficient web tool to collect valid emails and phone numbers based on a search query."
  },
  {
    question:"Can we get sales emails at Grab,io",
    answer: "Grab.io software can extract multiple valid business emails within 90 seconds."
  },
  {
    question:"Is it a free service?",
    answer: "There is a limit in the search querry for the free service of the application."
  }
]

const Faq = () => {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <div className=" mx-auto faq my-5 ">
      <Accordion flush open={open} toggle={toggle}>
        {questions.map((item, index)=> (
          <AccordionItem key={index} className=" py-2 px-3 my-3 rounded-3 border-bottom border-dark-subtle shadow-sm border-end">
          <AccordionHeader targetId={index+1} className="acc-head"><FaRegQuestionCircle className="me-3 fs-4 text-primary-emphasis"/> {item.question}</AccordionHeader>
          <AccordionBody accordionId={index+1} className=" px-3 py-4">
            {item.answer}
          </AccordionBody>
        </AccordionItem>
        ))}
        
      </Accordion>
    </div>
  );
};

export default Faq;
