import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useUserAuth } from "../Context/Context";
import withScrollToTop from "../components/scrollToTop";

const Signup = () => {
  const { register, user, getCountryCode } = useUserAuth();
  const [country, setCountry] = useState();
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState();
  const FirstnameRef = useRef();
  const LastnameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const countryRef = useRef();
  const passwordRef = useRef();
  const termsRef = useRef();

  useEffect(()=> {
    getCountryCode().then((res)=> setCountry(res));
  },[])
console.log(country);
  const handleChange = (refName) => {
    setErrors((prevErrors) => ({ ...prevErrors, [refName]: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const errors = {};
    const validateField = (field, value, errorMessage) => {
      if (
        !value ||
        (Array.isArray(value) && value.length === 0) ||
        value === "Select your country"
      ) {
        errors[field] = errorMessage;
      } else if (field === "email" && !/\S+@\S+\.\S+/.test(value)) {
        errors[field] = "Invalid email format";
      } else if (field === "mobile" && !/^[0-9]+$/.test(value)) {
        errors[field] = "Invalid mobile number format";
      }
    };

    validateField(
      "firstname",
      FirstnameRef.current.value,
      "First Name is required"
    );
    validateField(
      "lastname",
      LastnameRef.current.value,
      "Last Name is required"
    );
    validateField("email", emailRef.current.value, "Email is required");
    validateField(
      "mobile",
      mobileRef.current.value,
      "Mobile number is required"
    );
    validateField("country", countryRef.current.value, "Country is required");
    validateField(
      "password",
      passwordRef.current.value,
      "Password is required"
    );

    setErrors(errors);
    setIsSubmit(true);
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const firstname = FirstnameRef.current.value;
      const lastname = LastnameRef.current.value;
      const Email = emailRef.current.value;
      const phone = mobileRef.current.value;
      const address = country.find((item)=> item.name === countryRef.current.value).id;
      const password = passwordRef.current.value;

      register(firstname, lastname, Email, phone, address, password);
    }
    setIsSubmit(false);
  }, [isSubmit]);

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 py-5">
      <Form
        onSubmit={handleSubmit}
        className="p-5 rounded-5 shadow-lg text-dark w-100 max-width-400"
      >
        <div className="d-md-flex justify-content-between mb-3">
          <h1 className="pb-2 my-auto" style={{ fontFamily: "sans-serif" }}>
            Register
          </h1>
          <Link to="/signin" className="my-auto fs-6 text-dark ">
            Already a User | Login
          </Link>
        </div>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="username" className="fs-5">
                First Name
              </Label>
              <Input
                required
                className=""
                style={{
                  border: "",
                  boxShadow: "none",
                  borderBottom: "2px solid black",
                }}
                onFocus={() => {
                  FirstnameRef.current.style.border = "2px solid black";
                }}
                onBlur={() => {
                  FirstnameRef.current.style.border = "";
                  FirstnameRef.current.style.borderBottom = "2px solid black"; // Reset the border when focus is lost
                }}
                type="text"
                name="username"
                id="username"
                innerRef={FirstnameRef}
                invalid={!!errors.username}
                onChange={() => handleChange("firstname")}
              />
              {errors.firstname && (
                <FormFeedback>{errors.firstname}</FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="username" className="fs-5">
                Last Name
              </Label>
              <Input
                required
                className=""
                style={{
                  border: "",
                  boxShadow: "none",
                  borderBottom: "2px solid black",
                }}
                onFocus={() => {
                  LastnameRef.current.style.border = "2px solid black";
                }}
                onBlur={() => {
                  LastnameRef.current.style.border = "";
                  LastnameRef.current.style.borderBottom = "2px solid black"; // Reset the border when focus is lost
                }}
                type="text"
                name="username"
                id="username"
                innerRef={LastnameRef}
                invalid={!!errors.lastname}
                onChange={() => handleChange("lastname")}
              />
              {errors.lastname && (
                <FormFeedback>{errors.lastname}</FormFeedback>
              )}
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label for="email" className=" fs-5">
            Email
          </Label>
          <Input
            required
            className=""
            style={{
              border: "",
              boxShadow: "none",
              borderBottom: "2px solid black",
            }}
            onFocus={() => {
              emailRef.current.style.border = "2px solid black";
            }}
            onBlur={() => {
              emailRef.current.style.border = "";
              emailRef.current.style.borderBottom = "2px solid black"; // Reset the border when focus is lost
            }}
            type="email"
            name="email"
            id="email"
            innerRef={emailRef}
            invalid={!!errors.email}
            onChange={() => handleChange("email")}
          />
          {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
        </FormGroup>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="mobile" className=" fs-5">
                Mobile Number
              </Label>
              <Input
                required
                className=""
                style={{
                  border: "",
                  boxShadow: "none",
                  borderBottom: "2px solid black",
                }}
                onFocus={() => {
                  mobileRef.current.style.border = "2px solid black";
                }}
                onBlur={() => {
                  mobileRef.current.style.border = "";
                  mobileRef.current.style.borderBottom = "2px solid black"; // Reset the border when focus is lost
                }}
                type="text"
                name="mobile"
                id="mobile"
                innerRef={mobileRef}
                invalid={!!errors.mobile}
                onChange={() => handleChange("mobile")}
              />
              {errors.mobile && <FormFeedback>{errors.mobile}</FormFeedback>}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="country" className=" fs-5">
                Country
              </Label>
              <Input
                required
                className=""
                style={{
                  border: "",
                  boxShadow: "none",
                  borderBottom: "2px solid black",
                }}
                onFocus={() => {
                  countryRef.current.style.border = "2px solid black";
                }}
                onBlur={() => {
                  countryRef.current.style.border = "";
                  countryRef.current.style.borderBottom = "2px solid black"; // Reset the border when focus is lost
                }}
                type="select"
                name="country"
                id="country"
                innerRef={countryRef}
                invalid={!!errors.country}
                onChange={() => handleChange("country")}
              >
                <option>Select your country</option> {/* Added first option */}
                {country && country.map((item, index) => (
                  <option key={index}>{item.name}</option>
                ))}
              </Input>
              {errors.country && <FormFeedback>{errors.country}</FormFeedback>}
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="password" className=" fs-5">
            Password
          </Label>
          <Input
            required
            className=""
            style={{
              border: "",
              boxShadow: "none",
              borderBottom: "2px solid black",
            }}
            onFocus={() => {
              passwordRef.current.style.border = "2px solid black";
            }}
            onBlur={() => {
              passwordRef.current.style.border = "";
              passwordRef.current.style.borderBottom = "2px solid black"; // Reset the border when focus is lost
            }}
            type="password"
            name="password"
            id="password"
            innerRef={passwordRef}
            invalid={!!errors.password}
            onChange={() => handleChange("password")}
          />
          {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
        </FormGroup>
        <FormGroup check>
          <Input
            type="checkbox"
            className="border-dark"
            style={{ boxShadow: "none" }}
            required
          />{" "}
          <Label check>
            I agree to all statements in the{" "}
            <Link to="/conditions" className="text-decoration-underline">
              terms of services.
            </Link>
          </Label>
        </FormGroup>
        <div className="d-flex justify-content-between">
          <Button
          type="submit"
            color="primary"
            className="px-5 py-2 fs-4  mt-3"
            style={{
              fontFamily: "sans-serif",
              transition: "background-color 0.7s, color 0.2s, border 0s", // Add transition properties for border
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "transparent";
              e.target.style.color = "#0d6efd"; // Change text color to white
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "";
              e.target.style.color = ""; // Restore the original text color
            }}
          >
            Register
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default withScrollToTop(Signup);
