import { UncontrolledPopover, PopoverBody, PopoverHeader, Input, Label, Button } from "reactstrap";
import { FaFilePdf } from "react-icons/fa6";
import { BsDownload } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useState } from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Download = ({scrapeData, text}) => {
  const [fileType, setFileType] = useState("select");
console.log(text);
  const convertToExcel = (data) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Create a single worksheet
    const worksheetData = [];
  
    // Add headers to the worksheet
    worksheetData.push(["URL", "Email", "Phone Number"]);
  
    // Convert each data object to rows and add to the worksheet
    data.forEach((item) => {
      const maxLength = Math.max(item.Emails.length, item.phone_numbers.length);
      const urls = Array(maxLength).fill(item.url); // Repeat the URL for each email or phone number
  
      for (let i = 0; i < maxLength; i++) {
        const email = item.Emails[i] || "";
        const phoneNumber = item.phone_numbers[i] || "";
        worksheetData.push([urls[i], email, phoneNumber]);
      }
    });
  
    // Convert the worksheet data to a sheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Convert the workbook to a binary Excel file
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
    // Create a Blob from the Excel file data
    const blob = new Blob([excelFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // Save the Blob as a file using the file-saver library
    saveAs(blob, `${text}.xlsx`);
  };


  
  const convertToPdf = (data) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
  
    // Create a single worksheet
    const worksheetData = [];
  
    // Add headers to the worksheet
    worksheetData.push(["URL", "Email", "Phone Number"]);
  
    // Convert each data object to rows and add to the worksheet
    data.forEach((item) => {
      const maxLength = Math.max(item.Emails.length, item.phone_numbers.length);
      const urls = Array(maxLength).fill(item.url); // Repeat the URL for each email or phone number
  
      for (let i = 0; i < maxLength; i++) {
        const email = item.Emails[i] || "";
        const phoneNumber = item.phone_numbers[i] || "";
        worksheetData.push([urls[i], email, phoneNumber]);
      }
    });
  
    // Set the column widths based on A4 size page
    const columnWidths = ["40%", "40%", "20%"]; // Customize the widths as per your requirement
  
    // Convert the worksheet data to a sheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // Convert the workbook to a binary Excel file
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
    // Convert the Excel file to PDF
    const pdfDocument = pdfMake.createPdf({
      content: [
        {
          table: {
            widths: columnWidths,
            body: worksheetData
          }
        }
      ]
    });
  
    // Generate the PDF and make it downloadable
    pdfDocument.download(`${text}.pdf`);
  };

  return (
    <div>
      <button className="pushable" id="pop">
        <span className="shadow"></span>
        <span className="edge"></span>
        <span className="front">
          <BsDownload className="me-2 fw-bold" /> Download
        </span>
      </button>

      <UncontrolledPopover placement="bottom" target="pop" trigger="legacy">
        <PopoverHeader className="">
          <AiOutlineSetting className="fs-5 me-2" /> Download Options
        </PopoverHeader>
        <PopoverBody>
          <div className="d-flex justify-content-evenly">
          <FaFilePdf  className="text-danger fs-4 w-100" style={{cursor:"pointer"}} onClick={()=> convertToPdf(scrapeData)}/>
<RiFileExcel2Fill  className="text-success fs-4 w-100" style={{cursor:"pointer"}} onClick={()=> convertToExcel(scrapeData)}/>
       
          </div>
          </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default Download;
