import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { BsEye } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
function Preview({scrapedata, text}) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  
  return (
    <div>
        <button class="pushable d-none d-lg-block" onClick={toggle}>
                                    <span class="shadow"></span>
                                    <span class="edge"></span>
                                    <span class="front">
                                      <BsEye className="me-2 fs-3 fw-bold" />{" "}
                                      Preview
                                    </span>
                                  </button>
      <Modal isOpen={modal} toggle={toggle} fullscreen={true}>
      <div className="d-flex justify-content-between mx-3 mt-4 ">
          <p className=" fs-3 ">{text}</p>
          <p className=" fs-4">
            <AiOutlineClose onClick={toggle} style={{ cursor: "pointer" }} />
          </p>
        </div>
        <hr />
        <ModalBody>
        <Table bordered>
  <thead>
    <tr>
      <th>
        Url
      </th>
      <th>
        Email
      </th>
      <th>
        Phone Number
      </th>
    </tr>
  </thead>
  <tbody style={{userSelect:"none"}}>
    {scrapedata.map((item , index)=> {
 const maxLength = Math.max(item.Emails.length, item.phone_numbers.length);
 const urls = Array(maxLength).fill(item.url);

         return (
            <React.Fragment key={index}>
            {Array.from({ length: maxLength }, (_, i) => i).map((i) => (
              <tr key={i}>
                <td>{urls[i]}</td>
                <td>{item.Emails[i] || ""}</td>
                <td>{item.phone_numbers[i] || ""}</td>
              </tr>
            ))}
          </React.Fragment>
        )
    }
   )}
   {/* {scrapedata.map((item, index)=> {
   
    return(
        <tr key={index}>
            <td>
                {item.url}
            </td>
            <td>
                {item[index]?.Emails}
            </td>
            <td>
                {item[index]?.phone_numbers}
            </td>
        </tr>
           )
   } 
   )} */}
  </tbody>
</Table>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Preview;