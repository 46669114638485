import { Link } from "react-router-dom";

const Thankyou = () => {
  return (
    <div className="text-center min-vh-100 my-5 pt-5">
      <p className="display-4">Thank you for Subscribing!</p>
      <Link to="/signin" className="mx-auto">
                              <button
                                className="px-5 py-2 fs-4 my-5 selected"
                                disabled={false}
                              >
                                Explore
                              </button>
                            </Link>
    </div>
  );
};
export default Thankyou;
