import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { BiSolidDashboard, BiSolidKey, BiLogOut } from "react-icons/bi";
import { PiNotePencilBold } from "react-icons/pi";
import { AiOutlineProfile } from "react-icons/ai";
import { HiOutlineMailOpen } from "react-icons/hi";
import { RiProfileFill } from "react-icons/ri";
import { FaRegBell, FaRegFile } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
import dummypic from "../images/dummypic.png"
import { useEffect } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { useState } from "react";
import { VscAccount } from "react-icons/vsc";
import { useUserAuth } from "../Context/Context";

function Navvbar() {
  const { user, logout } = useUserAuth();
  const [isdropdown, setISDropdown] = useState(false);

  const toggleDropdown = () => {
    setISDropdown(!isdropdown);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(prevScrollPos > currentScrollPos);

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos;

      setVisible(visible);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Navbar
        expand={"md"}
        className={`bg-body-secondary w-100 border-bottom border-dark-subtle shadow-lg  py-3 navbar ${
          visible ? "navbar--visible" : "navbar--hidden"
        }`}
      >
        <Container fluid className="px-md-5">
          <Navbar.Brand
            as={Link}
            to="/"
            className="fs-2 fw-bold pe-md-5 text-primary zoom d-none d-md-block"
          >
            Grab.io
          </Navbar.Brand>
          {/* For Mobile users */}
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${"md"}`}
            onClick={handleShow}
          />
          {/* <Navbar.Brand
            as={Link}
            to="/"
            className="fs-2 fw-bold pe-md-5 text-primary zoom d-md-none"
          >
            Grab.io
          </Navbar.Brand> */}
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${"md"}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${"md"}`}
                className=" fw-bold text-primary fs-3"
              >
                Grab.io
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="me-auto">
                <NavLink
                  onClick={handleClose}
                  to="/about"
                  className={`my-2 my-md-0 mx-md-4 fw-bolder fs-6 text-decoration-none zoom `}
                  style={({ isActive }) => ({
                    color: isActive ? "#0d6efd" : "black",
                  })}
                >
                  About
                </NavLink>

                <NavLink
                  onClick={handleClose}
                  to="/pricing"
                  style={({ isActive }) => ({
                    color: isActive ? "#0d6efd" : "black",
                  })}
                  className={`my-2 my-md-0 mx-md-4 text-decoration-none fw-bolder fs-6 zoom`}
                >
                  Pricing
                </NavLink>

                <NavLink
                  onClick={handleClose}
                  to="/contact"
                  style={({ isActive }) => ({
                    color: isActive ? "#0d6efd" : "black",
                  })}
                  className={`my-2 my-md-0 mx-md-4 fw-bolder fs-6 text-decoration-none zoom `}
                >
                  Contact
                </NavLink>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <div className="">
            {user ? (
              <div>
                <Dropdown
                  isOpen={isdropdown}
                  toggle={toggleDropdown}
                  end={true}
                >
                  <DropdownToggle className="bg-body-secondary border-0">
                    {/* <VscAccount className="fs-1 text-dark" /> */}
                    <img
                      src={user.profile_image !== "" ? user.profile_image : dummypic}
                      alt=""
                      className="rounded-circle border border-dark border-2"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className={` pt-2 pb-3 px-2 bg-light border-2 border-dark-subtle`}
                    style={{ zIndex: 1001 }}
                  >
                    <DropdownItem
                      header
                      className="pt-2 px-3 mb-2 fs-6  fst-italic text-dark"
                    >
                    <span className="text-capitalize fw-bold">Hi, {user.first_name}</span>   <br /> <span style={{fontSize:"13px"}}>{user.email}</span> 
                    </DropdownItem>
                    <DropdownItem
                      className="px-5 py-3 fs-6 dropmenu border-bottom border-light-subtle text-capitalize bg-transparent"
                      tag={Link}
                      to="/scrape"
                    >
                      <HiOutlineMailOpen className="fs-4 me-3" /> Dashboard
                    </DropdownItem>
                    <DropdownItem
                      className=" px-5 py-3 fs-6 dropmenu border-bottom border-light-subtle text-capitalize bg-transparent"
                      tag={Link}
                      to="/myresult"
                    >
                      <FaRegFile className="fs-4 me-3" /> My Results
                    </DropdownItem>
                    <DropdownItem
                      className=" px-5 py-3 fs-6 dropmenu border-bottom border-light-subtle text-capitalize bg-transparent"
                      tag={Link}
                      to="/myprofile"
                    >
                      <RiProfileFill className="fs-4 me-3" /> My Profile
                    </DropdownItem>
                    {/* <DropdownItem
                      className=" px-5 py-3 fs-6 dropmenu border-bottom border-light-subtle text-capitalize bg-transparent"
                      tag={Link}
                      to="/subscriptiondetails"
                    >
                      <FaRegBell className="fs-4 me-3" /> My Subscription
                    </DropdownItem> */}
                    <DropdownItem
                      className=" px-5 py-3 fs-6 dropmenu border-bottom border-light-subtle text-capitalize bg-transparent"
                      tag={Link}
                      onClick={logout}
                      to="/"
                    >
                      <BiLogOut className="fs-4 me-3" /> Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            ) : (
              <>
                <Button
                  className="mx-3 text-uppercase  bg-light px-3 ms-auto but py-2"
                  as={Link}
                  to="/signup"
                >
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </Container>
      </Navbar>
    </>
  );
}

export default Navvbar;
