// Import necessary Reactstrap and Bootstrap components
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from "reactstrap";
import { GoRocket } from "react-icons/go";
import { BsStars } from "react-icons/bs"
import { CiHeart } from "react-icons/ci";
import teamwork from "../images/teamwork.jpg"
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper/modules";
// import required modules

// Define the testimonial data
const testimonials = [
  {
    id: 1,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur quidem minima nostrum maiores cum quaerat inventore? Numquam rem amet facere harum suscipit. Sint, placeat magni? Sunt, numquam. Omnis ratione eaque officia odit quis recusandae consectetur rerum sint itaque. Rem blanditiis iusto est obcaecati ex perferendis laborum mollitia provident fugiat optio.",
    author: "John Doe",
    position: "CEO, Company A",
  },
  {
    id: 2,
    keyword: "Fast contact",
    text: "Duis vehicula ex id orci fermentum, nec ullamcorper risus vestibulum. Suspendisse potenti.",
    author: "Jane Smith",
    position: "CTO, Company B",
  },
  {
    id: 3,
    keyword: "Fast contact",
    text: "Duis vehicula ex id orci fermentum, nec ullamcorper risus vestibulum. Suspendisse potenti.",
    author: "Jane Smith",
    position: "CTO, Company C",
  },
  {
    id: 4,
    keyword: "Fast contact",
    text: "Duis vehicula ex id orci fermentum, nec ullamcorper risus vestibulum. Suspendisse potenti.",
    author: "Jane Smith",
    position: "CTO, Company D",
  },
  {
    id: 5,
    keyword: "Fast contact",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione quam vel consectetur omnis perferendis obcaecati iure dolores molestiae expedita officiis.",
    author: "Jane Smith",
    position: "CTO, Company E",
  },
  {
    id: 6,
    keyword: "Fast contact",
    text: "Duis vehicula ex id orci fermentum, nec ullamcorper risus vestibulum. Suspendisse potenti.",
    author: "Jane Smith",
    position: "CTO, Company F",
  },
  // Add more testimonials as needed
];

// AboutUs component
const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // document.getElementById('website-title').innerText = "About"
  }, []);
  return (
    <div >
     <Container fluid className="px-0">
      <div className=" mb-5 py-5  aboutbg d-flex align-items-center ">
      <Container className=" pb-2 overlay-about px-5" data-aos="zoom-in-up" data-aos-duration="1000">
        <h1 className="heading text-center my-3 about-text">About Us</h1>
        <p className="fs-4 text-center d-none d-md-block pb-4 about-text">
          Welcome to Grab.io - Your Trusted Contact Discovery Platform!{" "}
        </p>
        <p className=" fs-6 pb-3 about-text">
          At Grab.io, our commitment to meaningful connections extends beyond
          mere functionality. We understand that in the realm of both
          professional and personal endeavors, success is often defined by the
          quality of the relationships we build. Our mission, therefore, is not
          just to provide a tool but to empower you with a cutting-edge and
          ethical solution that goes beyond the surface, facilitating the
          discovery of emails and contacts customized to your unique queries. <br /> <br /> In
          a digital landscape that is often cluttered with impersonal
          interactions, we set ourselves apart by fostering an environment where
          connections are forged with purpose. We take pride in offering a
          user-friendly platform that not only streamlines the process of data
          discovery but also prioritizes ethical practices. Connecting with the
          right people should not only be efficient and accurate but, above all,
          respectful of privacy.  <br /> <br /> In an era where digital
          connections are paramount, Grab.io stands as your trusted ally,
          navigating the landscape of data discovery with precision, ethics, and
          a genuine commitment to your success.
        </p>
       
      </Container>
      </div>
      </Container>
     
     <div className="otherbg">
      <Container className="py-3">
      {/* <hr className="my-2" /> */}
        <Row>
          <Col md={6} data-aos="fade-right" data-aos-duration="2000" className=" d-flex align-items-center">
            <div className="px-4">
            <img src={teamwork} alt="" className="img-fluid rounded-5"/>
            </div>
          
          </Col>
          <Col md={6} data-aos="fade-left" data-aos-duration="2000">
          <h2 className="mb-4">Our Mission</h2>
            <p className="fs-6 text-body-secondary py-2">
              We firmly believe that your success is our success. We're not just
              here to create blogs; we're here to help you establish your online
              presence, connect with your readers, and achieve your goals. Every
              blog we craft is a step toward strengthening your brand, expanding
              your reach, and driving meaningful engagement.
            </p>
            <hr />
            <h2 className="mb-4">Our Vision</h2>
            <p className="fs-6 text-body-secondary py-2">
              Our vision is to be the go-to destination for individuals and
              businesses seeking exceptional blog creation services. We aspire
              to be the bridge between your ideas and the digital world,
              enabling your stories to reach a wider audience and make a lasting
              impact.
            </p>
          </Col>
        </Row>
       
      
      </Container>
     </div>
      
      
<Container>
<hr className="my-2" />
<div className="my-5">
<h2 className="mt-2">Our Values</h2>
        <Row>
          <Col md={4}>
          <Card className=" mx-2 bg-transparent border-0 pb-0 pt-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className=" rounded-circle p-4">
                  <GoRocket className="" style={{fontSize:"3rem", color:"#1d99d2"}} />
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2">
                  Move fast and help others move.
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2 text-body-secondary">
                    We prioritize building the features our users absolutely need, as fast as possible.
                    </p>
                  </CardBody>
                </Card>
          </Col>
          <Col md={4}>
          <Card className=" mx-2 bg-transparent border-0 pb-0 pt-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className=" rounded-circle p-4">
                  <BsStars className="" style={{fontSize:"3rem", color:"#1d99d2"}} />
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2">
                  Push for simplicity.
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2 text-body-secondary">
                    We believe the simplest products have the most significant impact.
                    </p>
                  </CardBody>
                </Card>
          </Col>
          <Col md={4}>
          <Card className=" mx-2 bg-transparent border-0 pb-0 pt-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className=" rounded-circle p-4">
                  <CiHeart className="" style={{fontSize:"3rem", color:"#1d99d2"}} />
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2">
                  Care about our users' experience.
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2 text-body-secondary">
                    We take the experience of our users very seriously without letting issues go unanswered.
                    </p>
                  </CardBody>
                </Card>
          </Col>
          <Col md={4}>
          <Card className=" mx-2 bg-transparent border-0 pb-0 pt-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className=" rounded-circle p-4">
                  <GoRocket className="" style={{fontSize:"3rem", color:"#1d99d2"}} />
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2">
                  Move fast and help others move fast.
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2 text-body-secondary">
                    We prioritize building the features our users absolutely need, as fast as possible.
                    </p>
                  </CardBody>
                </Card>
          </Col>
           <Col md={4}>
          <Card className=" mx-2 bg-transparent border-0 pb-0 pt-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className=" rounded-circle p-4">
                  <GoRocket className="" style={{fontSize:"3rem", color:"#1d99d2"}} />
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2">
                  Move fast and help others move fast.
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2 text-body-secondary">
                    We prioritize building the features our users absolutely need, as fast as possible.
                    </p>
                  </CardBody>
                </Card>
          </Col>
        </Row>
</div>

</Container>
      {/* <Container className="mb-5 ">
        <h2>Testimonials</h2>
        <Swiper
          loop={true}
          slidesPerView={1}
          spaceBetween={30}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper py-5"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <Card
                className="py-5 rounded-5 shadow-sm"
                style={{ height: "400px" }}
              >
                <CardTitle className="text-center fw-bolder text-capitalize fs-4">
                  {testimonial.author}
                </CardTitle>
                <p className="text-center mb-0 fst-italic">
                  {testimonial.position}
                </p>
                <hr className="my-3" />
                <CardBody className="px-md-4 px-2 pb-0">
                  <CardText className="fs-5 fw-bolder">
                    {testimonial.keyword}
                  </CardText>
                  <CardText
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 5,
                      maxHeight: "200px",
                    }}
                  >
                    {testimonial.text}
                  </CardText>
                </CardBody>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container> */}
    </div>
  );
};

export default About;
