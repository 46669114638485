import { Container , Button} from "reactstrap";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useUserAuth } from "../Context/Context";
import { useEffect, useState } from "react";
import withScrollToTop from "./scrollToTop";
const Verify = () => {
  const {emailResend, userMail} = useUserAuth();
  const [newmail, setNewMail] = useState();
    const{key} =useParams();
    
    const navigate = useNavigate();
  
    const maskEmail = (email) => {
        const [username, domain] = email.split('@');
        const maskedUsername = username.slice(0, 3).padEnd(username.length, '*');
        return `${maskedUsername}@${domain}`;
      };
      console.log(userMail)
  
      useEffect(()=> {
        if(userMail)
        {
           setNewMail(maskEmail(userMail)) ;
        }
      }, [userMail])

      

      const handleSubmit = () => {
emailResend(key);
      }
  return (
    <div className="mt-5 pt-mb-5 min-vh-100 justify-content-center">
      {key && (
        <Container>
        <p className="fs-2 fw-bold text-capitalize">Welcome Aboard, Friend 👋</p>
        <p className="fs-5 text-secondary">Check your inbox to validate email and verify your account.</p>
        <p className="fs-5 text-secondary">
          A verification link has been sent to your email address <span className="fw-bold text-dark">{newmail}</span>.
          Please click on that link or fire the URL into browser to complete the
          email verification process.
        </p>
        <p>Didn't receive the email yet? <span className="text-decoration-underline fw-bolder" style={{cursor: "pointer"}} onClick={handleSubmit}>Click here</span> to resend email to your inbox.</p>
    
          <Link to="/signin" className="mx-auto">
                              <button
                                className="px-5 py-2 fs-5 mt-3 but"
                                disabled={false}
                              >
                                Access Now
                              </button>
                            </Link>
      </Container>
     )}
      
    </div>
  );
};
export default withScrollToTop(Verify);
