import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import {
  AiOutlineFileText,
  AiOutlinePicture,
  AiOutlineSafetyCertificate,
  AiOutlineApi,
  AiOutlineIdcard,
} from "react-icons/ai";

import { BsArrowRight, BsCloudDownloadFill } from "react-icons/bs";
import { TbDeviceDesktopSearch } from "react-icons/tb";
import { RiPassValidFill } from "react-icons/ri";
import landinganimation from "../images/landinganimation.json";
import Faq from "../components/faq";
import { useUserAuth } from "../Context/Context";
import stepsanimation from "../images/stepsanimation.json";
import withScrollToTop from "../components/scrollToTop";
import TestimonialSection from "../components/testimonial";
import ReviewCarousel from "../components/testimonial";
import Features from "../images/features.svg";
import icon1 from "../images/ser-icon9.png"

const Scrapelanding = () => {
  const { user } = useUserAuth();
  return (
    <div className="">
      <Container fluid className="px-0">
      <div className="min-vh-100 mb-5 pb-5 testbg d-flex align-items-center">
        <Container >
          <Row> 
            <Col
              lg={7}
              md={10}
              className="d-flex align-items-center flex-column justify-content-center"
              data-aos="fade-right"
            >
              <h1
                className=" text-capitalize  mx-auto mx-md-0 font"
                style={{
                  fontSize: "4rem",
                  letterSpacing: "2px",
                  lineHeight: "75px",
                }}
              >
                <span className="text-capitalize text-primary"> Grab </span> any
                contacts effortlessly.
                <br />
              </h1>
              <p className="fs-6 text-dark-subtle my-3">
                Introducing Grab.io, the game-changing website that generates
                valid leads within minutes, revolutionizing the lead generation
                process for businesses of all sizes.
              </p>
              <div className="me-auto">
                {user ? (
                  <>
                    <Button
                      color="primary"
                      className="text-capitalize px-4 py-2 fs-5 mt-3"
                      style={{
                        fontFamily: "sans-serif",
                        transition:
                          "background-color 0.5s, color 0.2s, border 0s", // Add transition properties for border
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "transparent";
                        e.target.style.color = "#0d6efd";
                        e.target.style.border = "2px solid #0d6efd"; // Add a 2px border on mouseenter
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "";
                        e.target.style.color = "";
                        e.target.style.border = ""; // Restore the original border
                      }}
                      tag={Link}
                      to="/scrape"
                    >
                      Start Free{" "}
                      <span className="ms-2 fs-4">
                        <BsArrowRight className="" />
                      </span>
                    </Button>
                  </>
                ) : (
                  <Button
                    color="primary"
                    className="text-capitalize px-4 py-2 fs-5 mt-3"
                    style={{
                      fontFamily: "sans-serif",
                      transition:
                        "background-color 0.5s, color 0.2s, border 0s", // Add transition properties for border
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "#0d6efd";
                      e.target.style.border = "2px solid #0d6efd"; // Add a 2px border on mouseenter
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.backgroundColor = "";
                      e.target.style.color = "";
                      e.target.style.border = ""; // Restore the original border
                    }}
                    tag={Link}
                    to="/signin"
                  >
                    Get Started For Free{" "}
                    <span className="ms-2 fs-4">
                      <BsArrowRight className="" />
                    </span>
                  </Button>
                )}
              </div>
            </Col>

            <Col className="" lg={5} md={10} data-aos="fade-left">
              <Container>
                <Lottie
                  animationData={landinganimation}
                  className="my-auto d-none d-md-block w-100 ms-auto"
                  style={{ transform: "scaleX(-1)" }}
                />
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      </Container>
     
      <Container>
        <div className="mt-5 px-5 px-md-0 pb-5">
          <div data-aos="fade-up">
            <p className="text-center heading mt-5">App Features</p>
            <p className="text-center mt-2 mb-5 w-75 mx-auto">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam,
              unde iusto consequuntur omnis quam illum vitae, explicabo vel in
              quia reiciendis sunt ducimus dolorum rem.
            </p>
          </div>

          <div className="">
            <Row>
              <Col md={12} lg={7} className="order-md-last">
                <div className="mx-auto" data-aos="zoom-in-left">
                  <Row >
                    <Col
                      xs="12"
                      md="6"
                      className="icon-box aos-init aos-animate my-3"
                      data-aos="fade-up"
                    >
                      <div className="d-flex">
                        <h1>
                          {" "}
                          <AiOutlineFileText className=" text-primary me-3 mb-2" />
                        </h1>
                        <div>
                          <h5 className="fw-bold">
                            Effortless Email Creation: Drag and Drop Template
                            Builder
                          </h5>
                          <p className="mt-3">
                            Creating stunning email campaigns has never been
                            easier. With Temptrio's user-friendly tool. Take
                            your email marketing to the next level with
                            Temptrio.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                      className="icon-box aos-init aos-animate my-3"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <div className="d-flex">
                        <h1>
                          {" "}
                          <AiOutlineIdcard className=" text-primary me-3 mb-2" />
                        </h1>
                        <div>
                          <h5 className="fw-bold">
                            Utilize templates and reusable content blocks to
                            save time
                          </h5>
                          <p className="mt-3">
                            Content blocks that you may utilize in different
                            email templates are simple to generate, save, and
                            manage.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                      className="icon-box aos-init aos-animate my-3"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div className="d-flex">
                        <h1>
                          {" "}
                          <AiOutlinePicture className=" text-primary me-3 mb-2" />
                        </h1>
                        <div>
                          <h5 className="fw-bold">
                            Pre-designed emails and landing pages
                          </h5>
                          <p className="mt-3">
                            Offer a selection of pre-designed templates for
                            different types of emails, industries, and
                            occasions.
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                      className="icon-box aos-init aos-animate my-3"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <div className="d-flex">
                        <h1>
                          {" "}
                          <AiOutlineSafetyCertificate className=" text-primary me-3 mb-2" />
                        </h1>
                        <div>
                          <h5 className="fw-bold">
                            A variety of choices for personalization
                          </h5>
                          <p className="mt-3">
                            Provide a wide range of customization options,
                            including the ability to add logos, images, text,
                            and buttons.
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} lg={5} className="d-flex align-items-center " data-aos="fade-right" data-aos-delay="100">
                <Container className="text-center">
                  <img
                    src={Features}
                    className="img-fluid"
                    alt=""
                  ></img>
                </Container>
              </Col>
            </Row>
          </div>
        </div>
        </Container>
        <div className="mb-5 pb-5 otherbg">
          
          <Container className=" py-5">
          <p className="text-center heading  pb-5" data-aos="fade-up">
            Reason To Choose Us
          </p>
            {/* <Row data-aos="fade-up">
              <Col
                className="d-flex justify-content-center align-items-center py-3 py-md-0 my-4 my-lg-0"
                lg={4}
                md={10}
              >
                <Card className="custom-card mx-2 py-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className="card-icon rounded-circle p-4">
                    <TbDeviceDesktopSearch style={{ fontSize: "3.5rem" }} />
                  </div>
                  <CardBody>
                    <p className="pt-5 mt-3 text-center fs-5">
                      {" "}
                      Unlock sales potential: Search any query, find contacts,
                      boost performance.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center py-3 py-md-0 my-4 my-lg-0"
                lg={4}
                md={10}
              >
                <Card className="custom-card mx-2  py-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className="card-icon rounded-circle p-4">
                    <RiPassValidFill style={{ fontSize: "3.5rem" }} />
                  </div>
                  <CardBody>
                    <p className="pt-5 text-center fs-5 mt-3">
                      {" "}
                      Effortless extraction: Multiple valid contacts, one
                      search, instant results.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center py-3 py-md-0 my-4 my-lg-0"
                lg={4}
                md={10}
              >
                <Card className="custom-card mx-2 py-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className="card-icon rounded-circle p-4">
                    <BsCloudDownloadFill style={{ fontSize: "3.5rem" }} />
                  </div>
                  <CardBody>
                    <p className="pt-5 text-center fs-5 mt-3">
                      {" "}
                      Swift and seamless downloads – Your search, hassle-free
                      and rapid.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            <Row>
              <Col lg={3} md={6} xs={12} className="my-2 my-lg-0">
              <Card className="custom-card mx-2  py-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className="card-icon rounded-circle p-4">
                    <img src={icon1} alt=""  className="image-fluid"/>
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2 cardtitle">
                  Effortless extraction
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2">
                       Multiple valid contacts, one
                      search, instant results.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3} md={6} xs={12} className="my-2 my-lg-0">
              <Card className="custom-card mx-2  py-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className="card-icon rounded-circle p-4">
                    <img src={icon1} alt=""  className="image-fluid"/>
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2 cardtitle">
                  Effortless extraction
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2">
                       Multiple valid contacts, one
                      search, instant results.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3} md={6} xs={12} className="my-2 my-lg-0">
              <Card className="custom-card mx-2  py-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className="card-icon rounded-circle p-4">
                    <img src={icon1} alt=""  className="image-fluid"/>
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2 cardtitle">
                  Effortless extraction
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2">
                       Multiple valid contacts, one
                      search, instant results.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3} md={6} xs={12} className="my-2 my-lg-0">
              <Card className="custom-card mx-2  py-4 px-3 rounded-5 mb-5 mb-md-0">
                  <div className="card-icon rounded-circle p-4">
                    <img src={icon1} alt=""  className="image-fluid"/>
                  </div>
                  <CardTitle className="text-capitalize fw-bolder fs-5 py-2 px-2 cardtitle">
                  Effortless extraction
                  </CardTitle>
                  <CardBody className="p-0">
                    <p className="pb-2 fs-6  px-2">
                       Multiple valid contacts, one
                      search, instant results.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div
        className=" bg-image py-5 px-0 mx-0 "
        // data-aos="fade-up"
        // data-aos-duration="1500"
      >
        <div className="overlay text-white w-100 m-auto">
          <h3 className="text-center heading pt-5">
            Get started today with 10 free searches.
          </h3>
          <p className="text-center my-3 fs-5">
            Find professional contacts of verified business in a matter of
            seconds
          </p>
          <div className="d-flex justify-content-center">
            <Button
              color="light"
              className="text-capitalize px-4 py-3 fs-6 mt-3 mb-5"
              style={{
                fontFamily: "sans-serif",
                letterSpacing: "2px",
                transition: "background-color 0.3s, color 0.2s, border 0s", // Add transition properties for border
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "transparent";
                e.target.style.color = "#ffffff";
                e.target.style.border = "2px solid #ffffff"; // Add a 2px border on mouseenter
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "";
                e.target.style.color = "";
                e.target.style.border = ""; // Restore the original border
              }}
              tag={Link}
              to="/pricing"
            >
              Plans & Pricing
            </Button>
          </div>
        </div>
      </div>
        <Container>
        <div className="my-5  pt-2 bg-soft-primary" data-aos="fade-in">
          <ReviewCarousel data-aos="fade-in" data-aos-delay="100" />
        </div>
        </Container>
      <div className=" py-5 otherbg">
        <Container>
          <p className="text-center heading mb-5" data-aos="fade-up">
            Frequently Asked Questions
          </p>
          <div data-aos="fade-up" className="">
            <Faq />
          </div>
        </Container>
      </div>
      
    </div>
  );
};
export default withScrollToTop(Scrapelanding);
