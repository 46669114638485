import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { country, countrycode } from "../country";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import searchanimation from "../images/searchanimation.json";
import { FaArrowLeft } from "react-icons/fa6";

import { TiTick } from "react-icons/ti";
import { BsDownload, BsEye } from "react-icons/bs";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { City, State } from "country-state-city";
import Preview from "./previewModal";
import Download from "./downloadFile";
import withScrollToTop from "./scrollToTop";
import { useUserAuth } from "../Context/Context";

const searchField = ["Hotels", "Fashion Stores", "Cafes", "Education Centers"];
const ScrapeData = () => {
  const {
    searchBalance,
    user,
    isLoading,
    FetchData,
    loadingData,
    scrdata,
    setScrdata,
    searchText,
    setSearchText,
    getRecentSearches,
  } = useUserAuth();
  const [searches, setSearches] = useState([]);
  const [isEmail, setIsEmail] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [listActive, setListActive] = useState(false);

  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(null);
  const [buttonselect, setButtonSelect] = useState(1);
  // const [active, setActive] = useState(false);
  const [errors, setErrors] = useState(null);
  const [submit, setSubmit] = useState(false);

  const [balance, setBalance] = useState();
  let listDataValue = "";
  const searchRef = useRef(null);
  const codeRef = useRef(null);
  const locationRef = useRef(null);
  const cityRef = useRef(null);
  let searchQuery = null;
  // let location = null;
  let codelocation = null;
  let newcode = "";
  useEffect(() => {
    if (user) {
      searchBalance(user.accountid, 0).then((res) => setBalance(res));
      getRecentSearches(user.accountid).then((res) => {
        // Assuming 'res.query' is an array of objects with 'query' and 'country' properties

        // Convert back to an array of objects
        setSearches(res.query.map((item) => item));
      });
    }
  }, [user]);

  const listToggle = () => {
    console.log("clicked");
    setListActive(!listActive);
  };

  const getListData = (item, country) => {
    // listDataValue = item;
    console.log(item, country)
    searchRef.current.value = item;
    codeRef.current.value = country;
    console.log(searchRef.current.value);
    console.log(codeRef.current.value);
  };
  const handleListItemClick = (item) => {
    listToggle();
    searchRef.current.value = item;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(searchRef.current.value);
    if (buttonselect !== 1) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        search:
          searchRef.current.value === "Example: Hotels"
            ? "Select a Query"
            : null,
        country:
          codeRef.current.value === "Select the country"
            ? "Select the country"
            : null,
        state:
          locationRef.current.value === "Select the state"
            ? "Select the state"
            : null,
        extract:
          isEmail === false && isNumber === false
            ? "Select extract mode"
            : null,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        country:
          codeRef.current.value === "Select the country"
            ? "Select the country"
            : null,
        extract:
          isEmail === false && isNumber === false
            ? "Select extract mode"
            : null,
      }));
    }
    setSubmit(true);
  };

  useEffect(() => {
    if (
      submit === true &&
      Object.values(errors).every((value) => value === null)
    ) {
      window.scrollTo(0, 0);
      const locale = country.filter(
        (item) => item.country === codeRef.current.value
      )[0].iso;
      const codelocation = locale.toLowerCase();
      const searchQuery =
        buttonselect === 1
          ? searchRef.current.value
          : cityRef.current.value !== "Select the city : optional"
          ? `${searchRef.current.value} in ${cityRef.current.value}`
          : `${searchRef.current.value} in ${locationRef.current.value}`;

      let newcode = "";
      if (isNumber === true) {
        newcode = country.filter(
          (item) => item.country === codeRef.current.value
        )[0].iso;
      }

      console.log(searchQuery, newcode, codelocation, isEmail, isNumber);
      FetchData(searchQuery, newcode, codelocation, isEmail, isNumber);
      setSubmit(false);
    }
  }, [submit, errors]);

  const toggleEmail = () => {
    setIsEmail(!isEmail);
  };

  const toggleNumber = () => {
    setIsNumber(!isNumber);
  };
  const handleCities = (e) => {
    const con = e.target.value;
    const code = states.filter((item) => item.name === con)[0].isoCode;
    const cun = country.filter(
      (item) => item.country === codeRef.current.value
    )[0].iso;
    // Get the cities of the selected country
    const countryCities = City.getCitiesOfState(cun, code);
    setCities(countryCities);
  };
  const handleStates = (e) => {
    const con = e.target.value;
    const code = country.filter((item) => item.country === con)[0].iso;
    const countryStates = State.getStatesOfCountry(code);
    setStates(countryStates);
  };
  const handleClear = () => {
    setScrdata(null);
    // setActive(false);
    setIsEmail(false);
    setIsNumber(false);
    setSearchText("");
    searchQuery = null;
    searchBalance(user.accountid, 0).then((res) => setBalance(res));
    // localStorage.removeItem("searchactive");
  };

  const refClear = () => {
    searchRef.current.value = "select";
    codeRef.current.value = "Select the country";
    // locationRef.current.value = "Select the state";
    // cityRef.current.value = "Select the city : optional";
    setIsEmail(false);
    setIsNumber(false);
    setSubmit(false);
    setErrors(null);
  };

  return (
    <div className="min-vh-100 d-flex  justify-content-center mt-5">
      <Container className="d-md-flex">
        <Container className="">
          {balance === "0" ? (
            <>
              <p
                className="fs-2 my-4 text-capitalize fw-bolder"
                style={{
                  fontFamily: "'Oswald', sans-serif",
                  wordSpacing: "3px",
                }}
              >
                You are one click away to avail valid contacts
              </p>
              <p className="fs-5">
                Remaining Searches : <span>{balance}</span>
              </p>
              <p className="my-5 display-6">
                Oops! You have exhausted all your seaches.{" "}
              </p>
              <p>
                Kindly upgrade your subscription to continue extracting leads.
              </p>
              <Button
                color="dark"
                tag={Link}
                to="/pricing"
                className={` px-md-4 py-md-3 fs-5 my-3 my-md-0 but`}
                outline
              >
                Upgrade Subscription
              </Button>
            </>
          ) : (
            <div>
              {!searchText ? (
                <>
                  <p
                    className="fs-2 my-4 text-capitalize fw-bolder"
                    style={{
                      fontFamily: "'Oswald', sans-serif",
                      wordSpacing: "3px",
                    }}
                  >
                    You are one click away to avail valid contacts
                  </p>
                  <p className="fs-5">
                    Remaining Searches : <span>{balance}</span>
                  </p>
                  {buttonselect === 1 ? (
                    <>
                      <Button
                        className="float-end fs-6 "
                        style={{
                          transition: "background-color 0.5s, color 0.3s",
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "white";
                          e.currentTarget.style.color = "black";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "black";
                          e.currentTarget.style.color = "white";
                        }}
                        onClick={() => {
                          setButtonSelect(2);
                          refClear();
                        }}
                      >
                        Filter search
                      </Button>
                      <Form
                        onSubmit={handleSubmit}
                        className="pt-3"
                        style={{ marginBottom: "30px" }}
                      >
                        <FormGroup>
                          <Label className="fs-5 fw-bolder">Your Query</Label>
                          <Input
                            className="py-3 mt-2"
                            name="search"
                            placeholder="Example: Hotels in Dubai"
                            type="text"
                            innerRef={searchRef}
                            required
                            style={{ border: "", boxShadow: "none" }}
                            onFocus={() => {
                              searchRef.current.style.border =
                                "1px solid black";
                              listToggle();
                            }}
                            onBlur={() => {
                              searchRef.current.style.border = "";
                            }}
                          />
                        </FormGroup>
                        
                        <ListGroup
                          className={`${listActive ? "d-block" : "d-none"}`}
                        >
                          {searches.map((item, index) => (
                            <ListGroupItem
                              key={index}
                              onClick={() => {
                                listToggle();
                                getListData(item.query, item.country);
                               
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              {item.query}
                            </ListGroupItem>
                          ))}
                        </ListGroup>

                        <FormGroup>
                          <Label className="fs-5 fw-bolder">
                            Search Country
                          </Label>
                          <Input
                            required
                            className="py-3 mt-2"
                            style={{ border: "", boxShadow: "none" }}
                            onFocus={() => {
                              setListActive(false);
                              codeRef.current.style.border = "1px solid black";
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                country: "",
                              }));
                            }}
                            onBlur={() => {
                              codeRef.current.style.border = ""; // Reset the border when focus is lost
                            }}
                            name="select"
                            type="select"
                            innerRef={codeRef}
                          >
                            <option disabled selected>
                              Select the country
                            </option>{" "}
                            {country.map((item, index) => (
                              <option key={index}>{item.country}</option>
                            ))}
                          </Input>
                          {errors && errors.country && (
                            <p className="text-danger">{errors.country}</p>
                          )}
                        </FormGroup>
                        {/* <FormGroup>
                      <Label className="fs-5 fw-bolder">Search Location</Label>
                      <Input
                        className=" py-3 mt-2"
                        name="search"
                        placeholder="Example : Sydney"
                        type="text"
                        innerRef={locationRef}
                        required
                        style={{ border: "", boxShadow: "none" }}
                        onFocus={() => {
                          locationRef.current.style.border = "1px solid black";
                        }}
                        onBlur={() => {
                          locationRef.current.style.border = ""; // Reset the border when focus is lost
                        }}
                      />
                    </FormGroup> */}
                        <Label className="fs-5 fw-bolder">Extract:</Label>
                        <FormGroup>
                          <Input
                            type="checkbox"
                            className="me-3  border border-dark-subtle border-2 box"
                            onClick={toggleEmail}
                            checked={isEmail}
                            style={{ boxShadow: "none", outline: "none" }}
                            onChange={() =>
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                extract: "",
                              }))
                            }
                          />
                          <Label check>Email</Label>
                        </FormGroup>
                        <FormGroup check inline className="d-md-flex ">
                          <Input
                            type="checkbox"
                            className="me-3  border border-dark-subtle border-2 box"
                            onClick={toggleNumber}
                            style={{ boxShadow: "none", outline: "none" }}
                            checked={isNumber}
                            onChange={() =>
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                extract: "",
                              }))
                            }
                          />
                          <Label check>Phone Number</Label>
                        </FormGroup>
                        {errors && errors.extract && (
                          <p className="text-danger">{errors.extract}</p>
                        )}
                        <FormGroup className="d-flex justify-content-md-end justify-content-center mt-3">
                          <button
                            type="submit"
                            className="px-5 py-2 fs-4 my-3  selected"
                            disabled={false}
                          >
                            Search
                          </button>
                        </FormGroup>
                      </Form>
                    </>
                  ) : (
                    <>
                      <Button
                        className="float-end fs-6 "
                        style={{
                          transition: "background-color 0.5s, color 0.3s",
                          backgroundColor: "black",
                          color: "white",
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.backgroundColor = "white";
                          e.currentTarget.style.color = "black";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.backgroundColor = "black";
                          e.currentTarget.style.color = "white";
                        }}
                        onClick={() => {
                          setButtonSelect(1);
                          refClear();
                        }}
                      >
                        Custom Search
                      </Button>
                      <Form
                        onSubmit={handleSubmit}
                        className="pt-3"
                        style={{ marginBottom: "30px" }}
                      >
                        <FormGroup>
                          <Label className="fs-5 fw-bolder">
                            {" "}
                            Select Search Field
                          </Label>
                          <Input
                            required
                            className="py-3 mt-2"
                            style={{ border: "", boxShadow: "none" }}
                            onFocus={() => {
                              searchRef.current.style.border =
                                "1px solid black";
                            }}
                            onBlur={() => {
                              searchRef.current.style.border = ""; // Reset the border when focus is lost
                            }}
                            onChange={() => {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                search: "",
                              }));
                            }}
                            name="select"
                            type="select"
                            innerRef={searchRef}
                          >
                            <option disabled selected>
                              Example: Hotels
                            </option>{" "}
                            {searchField.map((item, index) => (
                              <option key={index}>{item}</option>
                            ))}
                          </Input>
                          {errors && errors.search && (
                            <p className="text-danger">{errors.search}</p>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label className="fs-5 fw-bolder">
                            Select Country
                          </Label>
                          <Input
                            required
                            className="py-3 mt-2"
                            style={{ border: "", boxShadow: "none" }}
                            onFocus={() => {
                              codeRef.current.style.border = "1px solid black";
                            }}
                            onBlur={() => {
                              codeRef.current.style.border = ""; // Reset the border when focus is lost
                            }}
                            name="select"
                            type="select"
                            innerRef={codeRef}
                            onChange={(e) => {
                              handleStates(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                country: "",
                              }));
                            }}
                          >
                            <option disabled selected>
                              Select the country
                            </option>{" "}
                            {country.map((item, index) => (
                              <option key={index}>{item.country}</option>
                            ))}
                          </Input>
                          {errors && errors.country && (
                            <p className="text-danger">{errors.country}</p>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label className="fs-5 fw-bolder">Select State</Label>
                          <Input
                            required
                            className="py-3 mt-2"
                            style={{ border: "", boxShadow: "none" }}
                            onFocus={() => {
                              locationRef.current.style.border =
                                "1px solid black";
                            }}
                            onBlur={() => {
                              locationRef.current.style.border = ""; // Reset the border when focus is lost
                            }}
                            name="select"
                            type="select"
                            innerRef={locationRef}
                            onChange={(e) => {
                              handleCities(e);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                state: "",
                              }));
                            }}
                          >
                            <option disabled selected>
                              Select the state
                            </option>{" "}
                            {states &&
                              states.map((item, index) => (
                                <option key={index}>{item.name}</option>
                              ))}
                          </Input>
                          {errors && errors.state && (
                            <p className="text-danger">{errors.state}</p>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Label className="fs-5 fw-bolder">Select City</Label>
                          <Input
                            required
                            className="py-3 mt-2"
                            style={{ border: "", boxShadow: "none" }}
                            onFocus={() => {
                              cityRef.current.style.border = "1px solid black";
                            }}
                            onBlur={() => {
                              cityRef.current.style.border = ""; // Reset the border when focus is lost
                            }}
                            name="select"
                            type="select"
                            innerRef={cityRef}
                          >
                            <option disabled selected>
                              Select the city : optional
                            </option>{" "}
                            {cities &&
                              cities.map((item, index) => (
                                <option key={index}>{item.name}</option>
                              ))}
                          </Input>
                        </FormGroup>
                        <Label className="fs-5 fw-bolder">Extract:</Label>
                        <FormGroup>
                          <Input
                            type="checkbox"
                            checked={isEmail}
                            className="me-3  border border-dark-subtle border-2 box"
                            onClick={toggleEmail}
                            style={{ boxShadow: "none", outline: "none" }}
                            onChange={() =>
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                extract: "",
                              }))
                            }
                          />
                          <Label check>Email</Label>
                        </FormGroup>
                        <FormGroup check inline className="d-md-flex ">
                          <Input
                            type="checkbox"
                            className="me-3  border border-dark-subtle border-2 box"
                            onClick={toggleNumber}
                            checked={isNumber}
                            style={{ boxShadow: "none", outline: "none" }}
                            onChange={() =>
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                extract: "",
                              }))
                            }
                          />
                          <Label check>Phone Number</Label>
                        </FormGroup>
                        {errors && errors.extract && (
                          <p className="text-danger">{errors.extract}</p>
                        )}
                        <FormGroup className="d-flex justify-content-md-end justify-content-center mt-3">
                          <button
                            type="submit"
                            className="px-5 py-2 fs-4 my-3  selected"
                            disabled={false}
                          >
                            Search
                          </button>
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Container className="mt-md-2">
                    <Container
                      className=" w-100 bg-dark-subtle rounded-5 p-5 "
                      style={{ maxWidth: "800px" }}
                    >
                      {isLoading ? (
                        <>{loadingData()}</>
                      ) : (
                        <>
                          {scrdata && !scrdata.error ? (
                            <>
                              <p className="heading text-center">
                                Search Result
                              </p>

                              <div className="d-flex flex-row justify-content-lg-evenly justify-content-center py-5">
                                <Download
                                  scrapeData={scrdata}
                                  text={searchText}
                                />

                                <Preview
                                  scrapedata={scrdata}
                                  text={searchText}
                                />
                              </div>
                            </>
                          ) : (
                            scrdata && (
                              <>
                                <p className="heading text-center">
                                  Search Error
                                </p>
                                <p className="text-danger  fs-4 py-5">
                                  {scrdata.message}
                                </p>
                              </>
                            )
                          )}

                          <div className=" py-3 w-100">
                            <button
                              className="butback ps-4 pe-2 float-end "
                              onClick={handleClear}
                            >
                              <FaArrowLeft className="iconback " />
                              <div className="px-3">Back to Search</div>
                            </button>
                          </div>
                        </>
                      )}
                    </Container>
                  </Container>
                </>
              )}
            </div>
          )}
        </Container>
        <Container className={`mt-5 ${!searchText ? `d-block` : `d-none`}`}>
          <Lottie
            animationData={searchanimation}
            loop={false}
            className=" w-100 ms-auto d-none d-md-block"
            style={{ maxWidth: "600px" }}
          />
        </Container>
      </Container>
    </div>
  );
};
export default withScrollToTop(ScrapeData);
