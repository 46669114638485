import {
  Container,
  Table,
  Button,
  ButtonGroup,
  Badge,
  Modal,
  ModalBody,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import withScrollToTop from "../components/scrollToTop";

const Subscription = () => {
  const data = Array.from({ length: 15 }, (_, index) => ({
    id: index + 1,
    name: "Monthly",
    amount: "200",
    duration: `@mdo${index + 1}`,
    date: "10-Nov-2023",
    status: "Active",
  }));
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <Container className="mb-5 mt-2">
      <Row>
        <Col md={12}>
          <div className="w-100 my-3">
            <div className="d-flex justify-content-between fs-6 fw-bolder">
              <p>Membership Status :</p>
              <p className="text-success fw-bolder fst-italic fs-5">Active</p>
            </div>
            <div className="d-flex justify-content-between fs-6 fw-bolder">
              <p>Amount :</p>
              <p className="fst-italic">200</p>
            </div>
            <div className="d-flex justify-content-between fs-6 fw-bolder">
              <p>Next Billing Date :</p>
              <p className="fst-italic">10 Nov 2023</p>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div
            style={{ maxHeight: "400px", overflowY: "auto" }}
            className="tabel my-4"
          >
            <Table hover responsive className="rounded-3 w-100 " style={{fontSize:"13px"}}>
              <thead className="border-bottom border-dark">
                <tr>
                  <th>ID</th>
                  <th>Package</th>
                  <th>Amount</th>
                  <th>Duration</th>
                  <th>Expire</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.name}</td>
                    <td>{item.amount}</td>
                    <td>{item.duration}</td>
                    <td>{item.date}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <div className="mt-5 d-flex justify-content-between flex-column flex-md-row">
        <Button
          color="dark"
          tag={Link}
          to="/pricing"
          className={` px-3 py-2 fs-5 my-3 my-md-0 but`}
          outline
        >
          Upgrade Subscription
        </Button>

        {/* <Button
          color="dark"
          className={`border-0 px-3 py-2 fs-5 my-3 my-md-0 text-dark border-0 bg-danger-subtle fw-bolder`}
          outline
          onClick={toggle}
        >
          Cancel Subscription
        </Button> */}
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="lg"
        className="rounded-5 "
      >
        <div className="d-flex justify-content-between mx-3 mt-4 ">
          <p className=" fs-3 fw-bold">Cancel Subscription</p>
          <p className=" fs-4">
            <AiOutlineClose onClick={toggle} style={{ cursor: "pointer" }} />
          </p>
        </div>

        <ModalBody className=" mx-3">
          <div className="d-flex">
            <div>
              {/* <Lottie animationData={forgetanimation}
                       className=" d-none d-md-block w-75 float-start"
                 />       */}
            </div>
            <div className="w-100 my-auto">
              <p className="fs-5 fw-bolder my-4">
                Once you cancel this your monthly subscription will stop and
                your account shall convert to the free version on 10 Dec 2023
              </p>
              <Form>
                <Input
                  type="textarea"
                  className=" border border-dark border-1 my-3 p-2 h-100"
                  style={{ boxShadow: "none", minHeight: "100px" }}
                  placeholder="Cancelation Reason"
                  required
                />
                <Button
                  className="px-5 py-2 fs-4 mt-3 float-end"
                  style={{
                    backgroundColor: "#0F52BA",
                    fontFamily: "sans-serif",
                    transition: "background-color 0.5s, color 0.2s, border 0s",
                  }}
                  disabled={false}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "transparent";
                    e.target.style.color = "#0F52BA";
                    e.target.style.border = "2px solid #0d6efd";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "#0F52BA";
                    e.target.style.color = "white";
                    e.target.style.border = "";
                  }}
                >
                  Cancel
                </Button>
              </Form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
};
export default withScrollToTop(Subscription);
